import React, { useMemo } from 'react';
import { Chart } from 'react-google-charts';

const CChart = ({
  type,
  data,
  height,
  options,
}) => {
  const generalOptions = useMemo(() => ({
    backgroundColor: 'transparent',
    legend: {
      position: 'none',
    },
    chartArea: {
      top: 50,
      height: '80%',
    },
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    },
    ...options
  }), [options]);

  return (
    <Chart
      chartType={type}
      width='100%'
      height={height}
      data={data}
      options={generalOptions}
    />
  );
};

CChart.propTypes = {

};

CChart.defaultProps = {
  options: {},
};

export default CChart;