import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '../Container';
import Typography from '../Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Label = styled.label`
  display: flex;
  gap: 8px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};

  > span {
    color: ${props => props.theme.colors.neutral[props.disabled ? 'medium' : 'darkest']};
  }
`;

const Box = styled.input`
  appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid ${props => props.theme.colors.primary.medium};
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  position: relative;

  + svg {
    position: absolute;
    color: transparent;
  }
  
  &:disabled {
    cursor: default;
    border: 2px solid ${props => props.theme.colors.neutral.light};
    background-color: ${props => props.theme.colors.neutral.light};
    pointer-events: none;
  }
  
  &:focus {
    box-shadow: 0 0 10px #007a7e;
  }
  
  &:checked + svg {
    color: ${props => props.theme.colors.primary.medium};
  }
`;

const Checkbox = ({ label, checked, disabled, onChange }) => {
  return (
    <Container>
      <Label disabled={disabled}>
        <Container
          width={24}
          height={24}
          alignItems='center'
          justify='center'
          position='relative'
        >
          <Box
            disabled={disabled}
            type='checkbox'
            checked={checked}
            onChange={() => onChange(!checked)}
          />
          <FontAwesomeIcon icon={faCheck} />
        </Container>

        <Typography
          pointer={!disabled}
          as='span'
          fontSize={12}
          lineHeight={24}
        >
          {label}
        </Typography>
      </Label>
    </Container>
  );
};

Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
};

export default Checkbox;