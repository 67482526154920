import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { Container, Grid } from 'components/atoms';
import { SIDE_MENU_WIDTH, HEADER_HEIGHT } from 'util/constants';
import { containerWidth } from 'util/responsive';
import ASide from './ASide';
import Header from './Header';

const LayoutContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.primary.lightest};
`;

const ContentContainer = styled.div`
  padding-left: ${SIDE_MENU_WIDTH}px;
  padding-top: ${HEADER_HEIGHT}px;
  min-height: 100vh;
  width: 100%;

  //@media only screen and (min-width: 768px) {
  //  width: 90%;
  //}
  //
  // @media only screen and (min-width: 992px) {
  //   width: ${containerWidth.md}px;
  // }
  //
  // @media only screen and (min-width: 1200px) {
  //   width: ${containerWidth.lg}px;
  // }

  @media only screen and (min-width: 1600px) {
    width: 1500px;
  }
`;

const Layout = () => {
  return (
    <LayoutContainer>
      <ASide />
      <Header />
      <ContentContainer>
        <Container
          width='100%'
          style={{
            padding: '48px 64px',
          }}
        >
          <Outlet />
        </Container>
      </ContentContainer>
    </LayoutContainer>
  );
};

export default Layout;