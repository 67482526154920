import axios from 'util/axios';

/* USER */

export const userRegister = body => axios.post('/public/signup', body);
export const userVerifyAccount = body => axios.post('/public/signup/complete-signup', body);
export const userCompleteRegister = body => axios.post('/public/signup/request-verify', body);
export const userRequestResetPassword = body => axios.post('/public/signup/request-reset-password', body);
export const userVerifyRecovery = body => axios.post('/public/signup/validate-recovery-token', body);
export const userResetPassword = body => axios.post('/public/signup/reset-password', body);

/* INSTITUTIONS */

export const getInstitutions = params => axios.get('/student/institutions', { params });
export const getCareers = params => axios.get('/student/institution-careers', { params });

/* STUDENT */

export const createStudent = body => axios.post('/student/students', body);
export const changeCareer = body => axios.patch('/student/students/change-career', body);

/* EXAM PROCESS */

export const getExams = params => axios.get('/student/exam-processes', { params });
export const getYearsPhases = params => axios.get('/student/exam-processes/get-years-and-phases', { params });
export const createProcess = body => axios.post('/student/student-exam-processes', body);
export const startProcess = id => axios.get(`/student/student-exams/${id}/start`);
export const cancelProcess = id => axios.get(`/student/student-exams/${id}/annul`);
export const getExamIndications = () => axios.get('/student/exam-indications', {
  params: {
    page_size: 0,
  },
});
export const getProcesses = params => axios.get('/student/student-exam-processes', { params });
export const getProcess = id => axios.get(`/student/student-exam-processes/${id}`);
export const getStatements = params => axios.get('/student/statements', { params })

/* EXAM DETAILS */

export const getExam = id => axios.get(`/student/student-exams/${id}`);
export const getStatement = params => axios.get('/student/statements', { params });
export const getExamRanking = id => axios.get(`/student/student-exam-processes/${id}/personal-ranking`);

/* RANKING */

export const getRankingByInstitution = params => axios.get('/student/student-exam-processes/institution-ranking', { params });
export const getRankingByCareer = params => axios.get('/student/student-exam-processes/career-ranking', { params })

/* PROFILE */

export const getProfile = () => axios.get('/student/students/me');
export const getMyCourses = () => axios.get('/student/my-courses', {
  params: {
    page_size: 0,
  },
});

/* STATISTICS */

export const getHistoricCourse = params => axios.get('/student/student-exam-processes/course-historic-statistics', { params });
export const getHistoricGrade = params => axios.get('/student/student-exam-processes/sep-historic-statistics', { params })