import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Typography } from 'components/atoms';

const ElementWrapper = styled.div`
  margin-bottom: 8px;
  max-width: 100%;
  width: ${props => typeof props.width === 'string' ? props.width : `${props.width}px`};
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  position: absolute;
  left: 12px;
  cursor: text;
  transform: ${props => props.float ? 'translateY(-16px)' : 'none'};
  font-size: ${props => props.float ? '12px' : '16px'};
  color: ${props => props.theme.colors.placeholder};
  transition: 0.3s all;
`;

const ToggleContainer = styled.div`
  position: absolute;
  cursor: pointer;
  right: 16px;
`;

const InputFloatLabel = ({
  value,
  onChange,
  label,
  type,
  width,
  error,
}) => {
  const inputRef = useRef();
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);

  return (
    <ElementWrapper width={width}>
      <InputWrapper>
        <Input
          ref={inputRef}
          type={show ? 'text' : type}
          onChange={({ target }) => onChange(target.value)}
          value={value}
          onFocus={() => setActive(true)}
          onBlur={() => {
            if (!value) {
              setActive(false);
            }
          }}
          error={error}
        />
        <Label
          float={active}
          onClick={() => inputRef.current.focus()}
        >
          {label}
        </Label>
        {type === 'password' && (
          <ToggleContainer onClick={() => setShow(!show)}>
            <FontAwesomeIcon icon={show ? faEyeSlash : faEye} />
          </ToggleContainer>
        )}
      </InputWrapper>
      {error && (
        <Typography
          color='error'
          fontSize={12}
          lineHeight={18}
          mt={2}
        >
          *{error}
        </Typography>
      )}
    </ElementWrapper>
  );
};

InputFloatLabel.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['text', 'password']),
};

InputFloatLabel.defaultProps = {
  width: '100%',
  type: 'text',
};

export default InputFloatLabel;