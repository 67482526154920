import React from 'react';
import styled from 'styled-components';
import { Card } from 'components/atoms';

const FullPageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("${props => props.image}") no-repeat center center;
  background-size: cover;
`

const Filter = styled.div`
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

const FullPageLayout = ({ image, children }) => (
  <FullPageContainer image={image}>
    <Filter />
    <Card
      bgVariant='lightest'
      paddingVertical={32}
      paddingHorizontal={48}
      style={{
        zIndex: 1,
        maxHeight: '90vh',
      }}
    >
      {children}
    </Card>
  </FullPageContainer>
);

export default FullPageLayout;