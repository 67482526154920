import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faClone } from '@fortawesome/free-regular-svg-icons';
import { Button, Col, Container, Grid, Row, Skeleton, Typography } from 'components/atoms';
import { FormField, ExamRow } from 'components/molecules';
import { ExamCard } from 'components/organisms';
import { cancelProcess, createProcess, getExams, getProcesses, getYearsPhases } from 'util/request';
import {
  processStatus,
  STATUS_CREATED,
  STATUS_STARTED,
} from 'util/constants';

const PickExam = () => {
  const navigate = useNavigate();
  const [year, setYear] = useState();
  const [phase, setPhase] = useState();
  const [currentProcess, setCurrentProcess] = useState(null);

  const filterQuery = useQuery(
    ['dataPhase'],
    () => getYearsPhases()
      .then(({ data }) => data),
    {
      placeholderData: [],
    },
  );
  const processQuery = useQuery(
    ['currentProcess'],
    () => getProcesses({
      status_in: `${STATUS_CREATED},${STATUS_STARTED}`,
      student_exams_status_in: `${STATUS_CREATED},${STATUS_STARTED}`,
      page_size: 1,
    }).then(({ data }) => data.results),
    {
      cacheTime: 0,
    },
  );
  const examsQuery = useQuery(
    ['exams'],
    () => getExams({
      year: year?.id,
      phase: phase?.id,
    }).then(({ data }) => data.results),
  );
  const createMutation = useMutation(createProcess);
  const cancelMutation = useMutation(cancelProcess);

  useEffect(() => {
    if (processQuery.data && processQuery.data.length > 0) {
      setCurrentProcess(processQuery.data[0]);
    } else if (createMutation.data) {
      setCurrentProcess(createMutation.data.data);
    }
  }, [processQuery.data, createMutation.data]);

  const handleDeleteExam = uuid => {
    cancelMutation.mutate(uuid, {
      onSuccess: () => {
        setCurrentProcess(null);
        createMutation.reset();
        processQuery.remove();
      },
    });
  };

  const handleSearchExam = () => examsQuery.refetch();

  const handlePickExam = exam => {
    navigate(`/examen/${currentProcess.uuid}/${exam.uuid}`);
  };

  const handleCreateExam = id => {
    createMutation.mutate({
      exam_process: id,
    }, {
      onSuccess: () => {
        examsQuery.remove();
      },
    })
  };

  return (
    <Container width='100%'>
      <Typography
        as='h2'
        fontFamily='Sans'
        color='primary'
        variant='darkest'
        fontSize={24}
        lineHeight={40}
        fontWeight={600}
      >
        Rendir nuevo examen
      </Typography>

      <Container width='100%' mt={32}>
        {processQuery.isFetching && (
          <>
            <Skeleton width='80%' mb={16}/>
            <Skeleton width='100%' height={50}/>
          </>
        )}

        {(!processQuery.isFetching && !currentProcess) && (
          <Container width='100%' mb={32}>
            <Typography
              color='primary'
              variant='darkest'
              fontFamily='Sans'
              fontSize={20}
              lineHeight={24}
              mb={16}
            >
              Crear un nuevo examen
            </Typography>

            <Grid>
              <Row spaceless gap={32}>
                <Col sm={4}>
                  <FormField
                    disabled={filterQuery.isLoading}
                    type='select'
                    placeholder='Año'
                    options={Object.keys(filterQuery.data).map(key => ({
                      id: key,
                      label: key,
                    }))}
                    selected={year}
                    onSelect={value => setYear(value)}
                    icon={<FontAwesomeIcon icon={faCalendarCheck}/>}
                  />
                </Col>

                <Col sm={4}>
                  <FormField
                    disabled={filterQuery.isLoading || !year}
                    type='select'
                    placeholder='Fase'
                    options={year ? filterQuery.data[year.id].map(phase => ({
                      id: phase,
                      label: phase,
                    })) : []}
                    selected={phase}
                    onSelect={value => setPhase(value)}
                    icon={<FontAwesomeIcon icon={faClone}/>}
                  />
                </Col>

                <Col sm={4}>
                  <Button
                    disabled={!year && !phase}
                    color='positive'
                    content='Buscar examen'
                    onClick={handleSearchExam}
                  />
                </Col>
              </Row>
            </Grid>
          </Container>
        )}

        {currentProcess && currentProcess.student_exams.map(exam => (
          <ExamCard
            key={exam.uuid}
            onStart={() => handlePickExam(exam)}
            onDelete={() => handleDeleteExam(exam.uuid)}
            data={{
              ...exam,
              institution_career: currentProcess.institution_career,
              exam_process: currentProcess.exam_process,
            }}
          />
        ))}

        {(examsQuery.isFetching && !currentProcess) && (
          <>
            <Skeleton width='100%' height={32} mb={8}/>
            <Skeleton width='100%' height={32} mb={8}/>
            <Skeleton width='100%' height={32}/>
          </>
        )}

        {(!examsQuery.isFetching && examsQuery.data && !currentProcess) && (
          <>
            {examsQuery.data.length === 0 ? (
              <>
                <Typography>
                  No se encontraron resultados.
                </Typography>
              </>
            ) : (
              <Container
                width='100%'
                gap={16}
              >
                {examsQuery.data.map(exam => (
                  <ExamRow
                    key={exam.uuid}
                    data={exam}
                    loading={createMutation.isLoading}
                    onStart={() => handleCreateExam(exam.uuid)}
                  />
                ))}
              </Container>
            )}
          </>
        )}
      </Container>
    </Container>
  );
};

export default PickExam;