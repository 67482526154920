import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/atoms';
import { HEADER_HEIGHT, SIDE_MENU_WIDTH } from 'util/constants';
import { containerWidth } from 'util/responsive';
import ASide from './ASide';
import Header from './Header';

const LayoutContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.grayscale.light};
`;

const ContentContainer = styled.div`
  padding-left: ${SIDE_MENU_WIDTH}px;
  padding-top: ${HEADER_HEIGHT}px;
  min-height: 100vh;
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: ${containerWidth.sm}px;
  }

  @media only screen and (min-width: 992px) {
    width: ${containerWidth.md}px;
  }

  @media only screen and (min-width: 1200px) {
    width: ${containerWidth.lg}px;
  }

  @media only screen and (min-width: 1600px) {
    width: 1500px;
  }
`;

const LayoutProcess = ({ children }) => {
  return (
    <LayoutContainer width='100%'>
      <ASide />
      <Header />
      <ContentContainer>
        <Container
          width='100%'
          style={{
            padding: '24px 64px 48px',
          }}
        >
          {children}
        </Container>
      </ContentContainer>
    </LayoutContainer>
  );
};

export default LayoutProcess;