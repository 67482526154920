import styled from 'styled-components';
import PropTypes from 'prop-types';

const Message = styled.span`
  width: ${props => props.fluid ? '100%' : `${props.width}px`};
  border-color: 1px solid ${props => props.theme.colors[props.type].dark};
  border-radius: 16px;
  font-size: 14px;
  background-color: ${props => props.theme.colors[props.type].light};
  padding: 16px;
  color: ${props => props.theme.colors[props.type].darkest};
  text-align: ${props => props.textAlign};
  margin-top: ${props => props.mt}px;
  margin-bottom: ${props => props.mb}px;
`;

Message.propTypes = {
  type: PropTypes.oneOf(['positive', 'warning', 'error']).isRequired,
  fontSize: PropTypes.number,
  fluid: PropTypes.bool,
  width: PropTypes.number,
  children: PropTypes.node.isRequired,
  textAlign: PropTypes.oneOf(['left', 'right', 'center']),
  mt: PropTypes.number,
  mb: PropTypes.number,
};

Message.defaultProps = {
  fontSize: 12,
  fluid: true,
  width: undefined,
  textAlign: 'left',
  mt: 0,
  mb: 0,
}

export default Message;