import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Col, Container, Grid, Row } from 'components/atoms';
import { media } from 'util/responsive';

const PublicContainer = styled.div`
  min-height: 100vh;
  
  ${media.lg(css`
    height: 100vh;
  `)}
`;

const Image = styled.div`
  height: 100%;
  background-image: url("${props => props.url}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  ${media.lg(css`
    height: 100vh;
  `)}
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
  height: 100%;
  min-height: 100vh;
  
  ${media.lg(css`
    width: 90%;
    max-width: 488px;
  `)}
`;

const PublicLayout = ({ imageUrl, reverse, children }) => (
  <PublicContainer>
    <Grid>
      <Row style={{ flexDirection: reverse ? 'row-reverse' : 'row' }}>
        <Col spaceless xs={16} lg={8}>
          <Container position='relative' height='100%'>
            <Content>
              {children}
            </Content>
          </Container>
        </Col>
        <Col spaceless xs='none' lg={8}>
          <Image url={imageUrl} />
        </Col>
      </Row>
    </Grid>
  </PublicContainer>
);

PublicLayout.propTypes = {
  imageUrl: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
};

PublicLayout.defaultProps = {
  reverse: false,
};

export default PublicLayout;