import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Container, Typography } from 'components/atoms';
import { FullPageLayout } from 'layouts';
import StepTwo from './step-two';
import StepOne from './step-one';
import { createStudent, getCareers, getInstitutions } from 'util/request';
import { setStudentData } from 'store/session';
import Background from 'assets/images/student_background.jpg';

const tabOptions = [
  {
    id: 1,
    label: 'Paso 1',
  },
  {
    id: 2,
    label: 'Paso 2',
  },
];

const StepOption = ({ number, label, active, onClick }) => (
  <Container
    display='inline'
    alignItems='center'
    gap={16}
    onClick={() => {
      if (onClick) onClick();
    }}
    style={{
      cursor: onClick ? 'pointer' : 'default',
    }}
  >
    <Container
      width={40}
      height={40}
      alignItems='center'
      justify='center'
      borderRadius={23}
      bgColor={active ? 'info' : undefined}
      bgVariant={active ? 'darkest' : 'medium'}
      borderColor={active ? 'info' : 'neutral'}
      borderVariant={active ? 'darkest' : 'light'}
    >
      <Typography
        as='span'
        color={active ? 'grayscale' : 'neutral'}
        variant={active ? 'lightest' : 'light'}
        fontSize={20}
        lineHeight={24}
        fontWeight={700}
      >
        {number}
      </Typography>
    </Container>
    <Typography
      as='p'
      color={active ? 'info' : 'neutral'}
      variant={active ? 'darkest' : 'light'}
      fontSize={12}
      lineHeight={16}
      fontWeight={600}
      textAlign='center'
    >
      {label}
    </Typography>
  </Container>
);

const CreateStudent = () => {
  const dispatch = useDispatch();
  const institutionsQuery = useQuery(
    ['institutions'],
    () => getInstitutions({ page_size: 0 }),
  );
  const careerQuery = useQuery(
    ['careers'],
    () => getCareers({
      page_size: 0,
      institution: selectedInstitution,
    }),
    {
      enabled: false,
    },
  );
  const studentMutation = useMutation(createStudent)
  const [step, setStep] = useState(1);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [selectedCareer, setSelectedCareer] = useState(null);

  const handleGetCareers = () => {
    setStep(2);
    careerQuery.refetch();
  };

  const handleOnBack = () => {
    if (step === 2) {
      careerQuery.remove();
      setSelectedCareer(null);
      setStep(1);
    }
  };

  const handleCreateStudent = () => {
    studentMutation.mutate({
      institution_career: selectedCareer,
    }, {
      onSuccess: response => {
        const { data } = response;
        dispatch(setStudentData(data));
      }
    });
  }

  return (
    <FullPageLayout image={Background}>
      <Container width={728} gap={32} height='100%'>
        <Container
          width='100%'
          direction='row'
          justify='center'
          gap={24}
        >
          <StepOption
            number={1}
            label='Selecciona la institución'
            active={step === 1}
            onClick={step === 2 ? handleOnBack : undefined}
          />
          <StepOption
            number={2}
            label='Selecciona la carrera'
            active={step === 2}
          />
        </Container>

        <Container flex={1} width='100%'>
          {step === 1 && (
            <StepOne
              list={institutionsQuery.data?.data}
              loading={institutionsQuery.isLoading}
              selectedInstitution={selectedInstitution}
              onSelect={inst => setSelectedInstitution(inst)}
              onNext={handleGetCareers}
            />
          )}

          {step === 2 && (
            <StepTwo
              list={careerQuery.data?.data}
              loading={careerQuery.isFetching}
              isCreating={studentMutation.isLoading}
              selectedCareer={selectedCareer}
              onSelect={career => setSelectedCareer(career)}
              onNext={handleCreateStudent}
            />
          )}
        </Container>
      </Container>
    </FullPageLayout>
  );
};

export default CreateStudent;