import React from 'react';
import { BrowserRouter as Router, Outlet, Route, Routes, Navigate } from 'react-router-dom';
import Layout from 'layouts/Layout';
import { protectedRoutes, protectedRoutesNoLayout, routes } from 'util/routes';
import { useDispatch, useSelector } from 'react-redux';
import CreateStudent from '../pages/app/create-student';
import CompleteRegister from '../pages/public/complete-register';
import VerifyAccount from '../pages/public/verify-account';

const AppRouter = () => {
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);
  const isAuth = !!session.access;
  const isVerified = !!session.user.verified;
  const isStudent = !!session.user.student_data;
  const homePage = '/examenes/lista';

  return (
    <Router>
      <Routes>
        {routes.map(route => (
          <Route
            key={route.path}
            index={route.index}
            path={route.path}
            element={isAuth ? <Navigate replace to={homePage} /> : route.element} // TODO: replace home page "/"
          />
        ))}
        <Route
          path='/'
          element={isAuth ? <Outlet /> : <Navigate replace to='/login' />}
        >
          <Route
            path='/completar-verificacion'
            element={(!isVerified && !isStudent) ? <VerifyAccount /> : <Navigate replace to={homePage} />}
          />
          <Route
            path='/completar-registro'
            element={(!isVerified && !isStudent) ? <CompleteRegister /> : <Navigate replace to={homePage} />}
          />
          <Route
            path='/crear-estudiante'
            element={(isVerified && !isStudent) ? <CreateStudent /> : <Navigate replace to={homePage} />}
          />
          {protectedRoutesNoLayout.map(route => (
            <Route
              key={route.path}
              path={route.path}
              element={(isVerified && isStudent)
                ? route.element
                : <Navigate replace to={!isVerified ? '/completar-registro' : '/crear-estudiante'} />
              }
            />
          ))}
          <Route
            path='/'
            element={(isVerified && isStudent)
              ? <Layout />
              : <Navigate replace to={!isVerified ? '/completar-registro' : '/crear-estudiante'} />
            }
          >
            {protectedRoutes.map(route => (
              <Route
                key={route.path}
                index={route.index}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;