import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Col, Container, Grid, Message, Row, Typography } from 'components/atoms';
import { Form } from 'components/molecules';
import { ModalResetPassword } from 'components/organisms'
import { socialLogin, login } from 'store/session';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import { PublicLayout } from 'layouts';
import LoginImage from 'assets/images/login_image.png';
import Logo from 'assets/images/logo.svg';
import Google from 'assets/images/social/google.svg';
import Facebook from 'assets/images/social/facebook.svg';

const Login = () => {
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);
  const [keepLogin, setKeepLogin] = useState(false);
  const [showRecoveryPasswordModal, setShowRecoveryPasswordModal] = useState(false);
  const [form, setForm] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    setErrors(session.errors);
  }, [session.errors]);

  const handleChangeForm = (text, field) => {
    setForm({
      ...form,
      [field]: text,
    });
  };

  const handleLogin = e => {
    e.preventDefault();

    let valid = true;
    const err = {
      email: '',
      password: '',
    };

    if (!form.email) {
      err.email = 'Este campo no puede estar en blanco.';
      valid = false;
    }

    if (!form.password) {
      err.password = 'Este campo no puede estar en blanco';
      valid = false;
    }

    setErrors(err);

    if (!valid) return;

    dispatch(login(form));
  };

  const handleSocialLogin = ({ provider, data }) => {
    console.log(data);
    dispatch(socialLogin({
      provider,
      access_token: data.access_token || data.accessToken,
    }));
  }

  const handleSocialLogin2 = (response) => {
    console.log(response);
  }

  return (
    <PublicLayout imageUrl={LoginImage}>
      <img src={Logo} alt='Skillup' style={{ width: 200 }} />

      <Typography
        as='h2'
        color='primary'
        variant='dark'
        fontFamily='Sans'
        fontSize={40}
        fontWeight={700}
        mt={32}
        mb={8}
      >
        ¡Hola de nuevo!
      </Typography>

      <Typography
        fontSize={16}
        lineHeight={24}
        textAlign='center'
        mb={32}
      >
        Nos emociona que puedas estar una vez más con nosotros
      </Typography>

      <Form onSubmit={handleLogin}>
        <Form.Field
          label='Usuario'
          name='email'
          placeholder='Tu usuario'
          value={form.email}
          onChange={value => handleChangeForm(value, 'email')}
          errorMessage={errors.email}
        />

        <Form.Field
          password
          label='Password'
          placeholder='Tu password'
          value={form.password}
          onChange={value => handleChangeForm(value, 'password')}
          errorMessage={errors.password}
        />

        <Container mt={8}>
          <Grid>
            <Row>
              <Col>
                <Checkbox
                  label='Seguir conectado'
                  checked={keepLogin}
                  onChange={value => setKeepLogin(value)}
                />
              </Col>
              <Col>
                <Container alignItems='flex-end'>
                  <Typography
                    pointer
                    as='span'
                    color='highlight'
                    fontSize={14}
                    lineHeight={16}
                    textAlign='right'
                    onClick={() => setShowRecoveryPasswordModal(true)}
                  >
                    Recuperar contraseña
                  </Typography>
                </Container>
              </Col>
            </Row>
          </Grid>
        </Container>

        <Form.Button
          fluid
          loading={session.loading}
          onClick={handleLogin}
          content='Iniciar sesión'
        />
      </Form>

      {errors.non_field_errors && (
        <Message type='warning' mt={16} textAlign='center'>
          {errors.non_field_errors}
        </Message>
      )}

      {/*<Container width='100%' mt={32}>*/}
      {/*  <Typography*/}
      {/*    fontSize={14}*/}
      {/*    lineHeight={16}*/}
      {/*    textAlign='center'*/}
      {/*  >*/}
      {/*    O también puedes iniciar sesión con*/}
      {/*  </Typography>*/}

      {/*  <Container*/}
      {/*    width='100%'*/}
      {/*    direction='row'*/}
      {/*    gap={16}*/}
      {/*    mt={24}*/}
      {/*  >*/}
      {/*    <Button*/}
      {/*      fluid*/}
      {/*      type='border'*/}
      {/*      color='neutral'*/}
      {/*      onClick={() => {}}*/}
      {/*      content={*/}
      {/*        <Container*/}
      {/*          width='100%'*/}
      {/*          direction='row'*/}
      {/*          alignItems='center'*/}
      {/*          justify='center'*/}
      {/*          gap={16}*/}
      {/*        >*/}
      {/*          <img src={Google} style={{ width: 20, height: 20 }} alt='Google' />*/}
      {/*          <Typography*/}
      {/*            pointer*/}
      {/*            as='span'*/}
      {/*            color='neutral'*/}
      {/*            variant='darkest'*/}
      {/*            fontSize={16}*/}
      {/*            lineHeight={24}*/}
      {/*            fontWeight={700}*/}
      {/*          >*/}
      {/*            Google*/}
      {/*          </Typography>*/}
      {/*        </Container>*/}
      {/*      }*/}
      {/*    />*/}

      {/*    <Button*/}
      {/*      fluid*/}
      {/*      type='border'*/}
      {/*      color='neutral'*/}
      {/*      onClick={() => {}}*/}
      {/*      content={*/}
      {/*        <Container*/}
      {/*          width='100%'*/}
      {/*          direction='row'*/}
      {/*          alignItems='center'*/}
      {/*          justify='center'*/}
      {/*          gap={16}*/}
      {/*        >*/}
      {/*          <img src={Facebook} style={{ width: 20, height: 20 }} alt='Facebook' />*/}
      {/*          <Typography*/}
      {/*            pointer*/}
      {/*            as='span'*/}
      {/*            color='neutral'*/}
      {/*            variant='darkest'*/}
      {/*            fontSize={16}*/}
      {/*            lineHeight={24}*/}
      {/*            fontWeight={700}*/}
      {/*          >*/}
      {/*            Facebook*/}
      {/*          </Typography>*/}
      {/*        </Container>*/}
      {/*      }*/}
      {/*    />*/}
      {/*  </Container>*/}
      {/*</Container>*/}

      <Container width='100%' alignItems='center' mt={80}>
        <Typography
          as='span'
          color='neutral'
          fontSize={16}
          lineHeight={24}
        >
          ¿Aún no tienes una cuenta? &nbsp;
          <Link to='/registro'>
            <Typography
              pointer
              as='span'
              color='highlight'
              fontSize={16}
              lineHeight={24}
              fontWeight={600}
            >
              Registrate aquí
            </Typography>
          </Link>
        </Typography>
      </Container>

      <ModalResetPassword
        show={showRecoveryPasswordModal}
        onClose={() => setShowRecoveryPasswordModal(false)}
      />
    </PublicLayout>
  );
};

export default Login;