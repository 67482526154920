import React, { useMemo } from 'react';
import styled from 'styled-components';
import { faArrowRightLong, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { faFileLines, faCalendarCheck, faClone, faCalendarXmark } from '@fortawesome/free-regular-svg-icons';
import { Button, Card, Col, Container, Grid, Icon, Row, Typography } from 'components/atoms';
import { Dropdown } from 'components/molecules';
import { formatDate } from 'util/functions';
import { processStatus, statusDisplay } from 'util/constants';

const DataWrapper = styled.div`
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid ${props => '#BBAAAA'}; //TODO: change theme color
  
  &:last-child {
    border-bottom: none;
  }
`;

const ExamCard = ({
  onDelete,
  onStart,
  data,
}) => {
  const dropdownOptions = useMemo(() => ([
    {
      id: 'delete',
      label: 'Eliminar',
      icon: <Icon icon={faCalendarXmark} />,
      color: 'warning',
      onClick: onDelete,
    },
  ]), []);

  return (
    <Card
      padding={0}
      bgVariant='lightest'
      width='100%'
      style={{ marginBottom: 16 }}
    >
      <DataWrapper>
        <Container
          width='100%'
          alignItems='flex-end'
          mb={24}
        >
          <Dropdown
            icon={<Icon icon={faEllipsis} color='highlight' />}
            options={dropdownOptions}
          />
        </Container>
        <Grid>
          <Row spaceless>
            <Col sm={10}>
              <Container
                direction='row'
                alignItems='center'
                gap={16}
              >
                <Icon icon={faFileLines} color='highlight' />
                <Typography
                  fontSize={16}
                  lineHeight={24}
                >
                  {data.institution_career?.institution.abbreviation} - {data.exam?.description}
                </Typography>
              </Container>
            </Col>

            <Col sm={3}>
              <Container
                direction='row'
                alignItems='center'
                gap={16}
              >
                <Icon icon={faClone} color='highlight' />
                <Typography
                  fontSize={16}
                  lineHeight={24}
                >
                  {data.exam_process?.year} - {data.exam_process?.phase}
                </Typography>
              </Container>
            </Col>

            <Col sm={3}>
              <Container
                direction='row'
                alignItems='center'
                justify='flex-end'
                gap={16}
              >
                <Icon icon={faCalendarCheck} color='highlight' />
                <Typography
                  fontSize={16}
                  lineHeight={24}
                >
                  {formatDate(data.created_at)}
                </Typography>
              </Container>
            </Col>
          </Row>
        </Grid>
      </DataWrapper>
      <DataWrapper width='100%'>
        <Grid>
          <Row spaceless>
            <Col sm={8}>
              <Typography
                color='highlight'
                fontSize={16}
                lineHeight={24}
              >
                Estado:&nbsp;
                <Typography
                  as='span'
                  color='warning'
                  variant='dark'
                >
                  {statusDisplay[data.status_display]}
                </Typography>
              </Typography>
            </Col>
            <Col sm={8}>
              <Typography
                color='highlight'
                fontSize={16}
                lineHeight={24}
                textAlign='right'
              >
                Carrera:&nbsp;
                <Typography
                  as='span'
                  color='neutral'
                  variant='darkest'
                >
                  {data.institution_career?.career.name}
                </Typography>
              </Typography>
            </Col>
          </Row>
        </Grid>
        {data.status_display !== processStatus.PROCESSED && (
          <Container
            width='100%'
            alignItems='flex-end'
            mt={24}
          >
            <Button
              onClick={onStart}
              content={data.status_display === processStatus.CREATED ? 'Iniciar examen' : 'Continuar examen'}
              iconPosition='right'
              weight={400}
              icon={<Icon icon={faArrowRightLong} />}
            />
          </Container>
        )}
      </DataWrapper>

    </Card>
  );
};

export default ExamCard;