import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LayoutProcess } from 'layouts';
import { Statement } from 'components/organisms';
import { Container, Grid } from 'components/atoms';
import { useParams } from 'react-router-dom';
import { processStatus } from '../../../../util/constants';

const Started = () => {
  const statements = useSelector(state => state.examProcess.statement);
  const status = useSelector(state => state.examProcess.status);
  const canEdit = status === processStatus.STARTED;

  useEffect(() => {
    if (typeof window?.MathJax !== 'undefined') {
      setTimeout(() => {
        window.MathJax.typeset();
      }, 100);
    }
  }, [statements]);

  return (
    <LayoutProcess>
      <Grid container>
        {statements.map((item, index) => {
          const prevSubject = statements[index - 1]
            ? statements[index - 1].course.name
            : '';
          return (
            <Statement
              key={item.uuid}
              onlyRead={!canEdit}
              prevSubject={prevSubject}
              data={item}
            />
          );
        })}
      </Grid>
    </LayoutProcess>
  );
};

export default Started;