import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'util/axios';
import { mapErrors } from 'util/functions';

export const login = createAsyncThunk('session/login', async (form, { rejectWithValue }) => {
  try {
    const response = await axios.post('/public/login', form);
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response.data)
  }
});

export const socialLogin = createAsyncThunk('session/socialLogin', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/public/login/${data.provider}`,
      {
        [data.provider === 'facebook' ? 'access_token' : 'code']: data.access_token,
      }
    );
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response.data)
  }
});

export const refreshData = createAsyncThunk('session/refreshData', async (arg, { rejectWithValue }) => {
  try {
    const response = await axios.get('/student/students/me');
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response.data)
  }
});

const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    access: '',
    refresh: '',
    user: {},
    loading: false,
    errors: {},
  },
  reducers: {
    setStudentData(state, action) {
      state.user.student_data = action.payload;
    },
    logout(state) {
      localStorage.removeItem(process.env.NODE_ENV !== 'production' ? 'AUTH_SKILLUP_DEV' : 'AUTH_SKILLUP');
      state.access = '';
      state.refresh = '';
      state.user = {};
      state.errors = {}
    },
  },
  extraReducers: builder => {
    builder.addCase(login.pending, state => {
      state.user = {};
      state.errors = {};
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      localStorage.setItem(process.env.NODE_ENV !== 'production' ? 'AUTH_SKILLUP_DEV' : 'AUTH_SKILLUP', action.payload.access);
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
      state.user = action.payload.user;
      state.loading = false;
      state.errors = {};
    });
    builder.addCase(login.rejected, (state, action) => {
      state.user = {};
      state.loading = false;
      state.errors = mapErrors(action.payload.errors);
    });

    builder.addCase(socialLogin.pending, state => {
      state.user = {};
      state.errors = {};
      state.loading = true;
    });
    builder.addCase(socialLogin.fulfilled, (state, action) => {
      localStorage.setItem(process.env.NODE_ENV !== 'production' ? 'AUTH_SKILLUP_DEV' : 'AUTH_SKILLUP', action.payload.access);
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
      state.user = action.payload.user;
      state.loading = false;
      state.errors = {};
    });
    builder.addCase(socialLogin.rejected, (state, action) => {
      state.user = {};
      state.loading = false;
      state.errors = mapErrors(action.payload.errors);
    });

    builder.addCase(refreshData.pending, state => {
      state.loading = true;
    });
    builder.addCase(refreshData.fulfilled, (state, action) => {
      state.loading = false;
      state.user = {
        ...state.user,
        student_data: {
          institution_career: action.payload.institution_career,
        },
      };
    });
  },
});

const { actions, reducer } = sessionSlice;

export const { setStudentData, logout } = actions;

export default reducer;
