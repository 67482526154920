import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Container, Typography } from 'components/atoms';
import { useDispatch, useSelector } from 'react-redux';
import { finishProcess } from '../../../store/examProcess';
import { ReactComponent as Timer } from 'assets/images/svgs/timer.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > span {
    width: 135px;
  }
`;

const Chronometer = () => {
  const dispatch = useDispatch();
  const startAt = useSelector(state => state.examProcess.startAt);
  const data = useSelector(state => state.examProcess.examData);
  const duration = data.setup?.time;
  const [time, setTime] = useState(0);

  const updateTime = () => {
    const now = new Date();
    setTime(startAt ? ((now.getTime() - (new Date(startAt)).getTime()) / 1000) : 0);
  };

  const handleOnFocus = () => updateTime();

  useEffect(() => {
    updateTime();
    window.addEventListener('focus', handleOnFocus);

    return () => window.removeEventListener('blur', handleOnFocus);
  }, []);

  useEffect(() => {
    let intervalId;

    if (Math.floor(time) >= duration) {
      dispatch(finishProcess());
    } else {
      intervalId = setInterval(() => setTime(time + 1), 1000);
    }

    return () => clearInterval(intervalId);
  }, [time]);

  const hours = Math.floor((duration - time) / 3600);
  const minutes = Math.floor(((duration - time) % 3600) / 60);
  const seconds = Math.floor(((duration - time) % 60));

  return (
    <Wrapper>
      <Timer/>
      <Typography
        as='span'
        fontSize={32}
        lineHeight={40}
        fontWeight={700}
      >
        {hours}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
      </Typography>
    </Wrapper>
  );
};

export default Chronometer;