import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Input, Select, Typography } from 'components/atoms';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import Exclamation from 'assets/images/svgs/exclamation.svg';

const ElementWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const IconContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  position: absolute;
  right: ${props => props.position === 'right' ? '16px' : 'auto'};
  left: ${props => props.position === 'left' ? '16px' : 'auto'};
  
  svg {
    color: ${props => props.theme.colors.primary.medium};
    font-size: 20px;
  }
`;

const ToggleText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    color: #7F7F7F;
  }
`;

const FormField = ({
  value,
  name,
  onChange,
  label,
  type,
  width,
  password,
  errorMessage,
  error,
  disabled,
  placeholder,
  icon,
  iconPosition,
  onSelect,
  options,
  loading,
  search,
  selected,
  capitalize,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const renderElement = () => {
    switch (type) {
      case 'select':
        return (
          <Select
            search={search}
            loading={loading}
            disabled={disabled}
            placeholder={placeholder}
            selected={selected}
            options={options}
            onSelect={onSelect}
            error={!!(error || errorMessage)}
            icon={icon}
            {...props}
          />
        );
      case 'input':
      default:
        return (
          <Input
            name={name}
            value={value}
            placeholder={placeholder}
            type={(password && !show) ? 'password' : undefined}
            onChange={({ target }) => onChange(target.value)}
            error={!!(error || errorMessage)}
            disabled={disabled}
            hasIcon={password || !!icon}
            iconPosition={password ? 'right' : iconPosition}
            style={{
              textTransform: capitalize ? 'capitalize' : 'none',
            }}
          />
        );
    }
  };

  return (
    <div style={{ width }}>
      {label && (
        <Typography
          as='label'
          color='primary'
          variant='dark'
          fontSize={12}
          lineHeight={16}
          mb={8}
          style={{
            display: 'inline-block',
          }}
        >
          {label}
        </Typography>
      )}
      <ElementWrapper>
        {(icon && iconPosition === 'left') && (
          <IconContent position='left'>
            {icon}
          </IconContent>
        )}
        {renderElement()}
        {(password || (icon && iconPosition === 'right')) && (
          <IconContent position='right'>
            {password && (
              <ToggleText onClick={() => setShow(!show)}>
                <FontAwesomeIcon icon={show ? faEyeSlash : faEye} />
              </ToggleText>
            )}
            {icon}
          </IconContent>
        )}
      </ElementWrapper>
      {errorMessage && (
        <Typography
          as='span'
          color='warning'
          variant='dark'
          fontSize={12}
          textAlign='right'
          mt={8}
          style={{
            display: 'block',
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

FormField.propTypes = {
  type: PropTypes.oneOf(['input', 'select']),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorMessage: PropTypes.string,
  password: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  // select
  search: PropTypes.bool,
  loading: PropTypes.bool,
  selected: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })),
  capitalize: PropTypes.bool,
};

FormField.defaultProps = {
  type: 'input',
  label: undefined,
  placeholder: '',
  name: undefined,
  value: undefined,
  width: '100%',
  errorMessage: '',
  password: false,
  disabled: false,
  error: false,
  icon: undefined,
  iconPosition: 'left',
  search: false,
  loading: false,
  selected: undefined,
  onSelect: undefined,
  options: [],
  onChange: undefined,
  capitalize: false,
};

export default FormField;