import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colorValues, variantValues } from '../../../util/theme';

const Card = styled.div(props => ({
  backgroundColor: props.theme.colors[props.bgColor][props.bgVariant],
  borderRadius: props.borderRadius,
  boxShadow: '0px 6px 12px 0px rgba(12, 39, 31, 0.20)',
  overflow: 'hidden',
  padding: props.padding,
  paddingBottom: props.paddingHorizontal,
  paddingTop: props.paddingHorizontal,
  paddingRight: props.paddingVertical,
  paddingLeft: props.paddingVertical,
  width: typeof props.width === 'string' ? props.width : `${props.width}px`,
  maxWidth: typeof props.maxWidth === 'string' ? props.maxWidth : `${props.maxWidth}px`,
  minWidth: typeof props.minWidth === 'string' ? props.minWidth : `${props.minWidth}px`,
}));

Card.propTypes = {
  bgColor: PropTypes.oneOf(colorValues),
  bgVariant: PropTypes.oneOf(variantValues),
  borderRadius: PropTypes.number,
  padding: PropTypes.number,
  paddingHorizontal: PropTypes.number,
  paddingVertical: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Card.defaultProps = {
  bgColor: 'grayscale',
  bgVariant: 'medium',
  padding: 32,
  borderRadius: 16,
  width: 'auto',
  maxWidth: 'none',
  minWidth: 'none',
};

export default Card;