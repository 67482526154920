import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { Button, Container, Modal, Typography } from 'components/atoms';
import { getExamIndications } from 'util/request';
import { ReactComponent as Warning } from 'assets/images/svgs/warning.svg';
import { ReactComponent as TickCircle } from 'assets/images/svgs/tick-circle.svg';
import { secondsFormatter } from '../../../util/functions';

const GeneralContainer = styled.div`
  padding-bottom: 8px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.light};
`;

const ModalExamIndications = ({
  show,
  onClose,
  data,
  onStart,
}) => {
  const [loading, setLoading] = useState(false);
  const indicationsQuery = useQuery(
    ['indications'],
    () => getExamIndications().then(response => {
      const {data} = response;
      return data;
    }),
  );

  return (
    <Modal
      overlayClose={!loading}
      show={show}
      maxWidth={568}
      onClose={onClose}
    >
      <Container width='100%' alignItems='center'>
        <Typography
          as='h2'
          color='primary'
          variant='dark'
          fontSize={20}
          lineHeight={24}
          fontWeight={700}
          textAlign='center'
          mb={32}
        >
          Algunas indicaciones antes de comenzar
        </Typography>

        <GeneralContainer>
          {indicationsQuery.data && indicationsQuery.data.map(indication => (
            <Container
              key={indication.id}
              direction='row'
              gap={16}
              mb={16}
            >
              <Container width={24}>
                <Warning />
              </Container>
              <Typography
                fontSize={14}
                lineHeight={18}
              >
                {indication.name}
              </Typography>
            </Container>
          ))}
        </GeneralContainer>

        <Container
          width='100%'
          direction='row'
          alignItems='center'
          gap={16}
          mb={16}
        >
          <Container width={24}>
            <TickCircle />
          </Container>
          <Typography
            fontSize={14}
            lineHeight={18}
          >
            El examen cuenta con {data?.setup?.exam_question_number} preguntas de alternativa única.
          </Typography>
        </Container>

        <Container
          width='100%'
          direction='row'
          alignItems='center'
          gap={16}
          mb={16}
        >
          <Container width={24}>
            <TickCircle />
          </Container>
          <Typography
            fontSize={14}
            lineHeight={18}
          >
            El tiempo límite para resolver el examen es de {secondsFormatter(data?.setup?.time)} minutos.
          </Typography>
        </Container>

        <Container
          width='100%'
          direction='row'
          alignItems='center'
          gap={16}
          mb={16}
        >
          <Container width={24}>
            <TickCircle />
          </Container>
          <Typography
            fontSize={14}
            lineHeight={18}
          >
            La forma de calificación del examen es el siguiente:
          </Typography>
        </Container>

        <Container
          width='100%'
          direction='row'
          justify='center'
          mb={8}
        >
          <Container width='25%'>
            <Typography
              color='primary'
              variant='dark'
              fontSize={12}
              lineHeight={16}
            >
              Buenas:
            </Typography>
          </Container>

          <Container width='25%'>
            <Typography
              fontSize={12}
              lineHeight={16}
            >
              {data?.setup?.good} puntos
            </Typography>
          </Container>
        </Container>
        <Container
          width='100%'
          direction='row'
          justify='center'
          mb={8}
        >
          <Container width='25%'>
            <Typography
              color='primary'
              variant='dark'
              fontSize={12}
              lineHeight={16}
            >
              Malas:
            </Typography>
          </Container>

          <Container width='25%'>
            <Typography
              fontSize={12}
              lineHeight={16}
            >
              {data?.setup?.bad} puntos
            </Typography>
          </Container>
        </Container>
        <Container
          width='100%'
          direction='row'
          justify='center'
          mb={8}
        >
          <Container width='25%'>
            <Typography
              color='primary'
              variant='dark'
              fontSize={12}
              lineHeight={16}
            >
              No respondidas:
            </Typography>
          </Container>

          <Container width='25%'>
            <Typography
              fontSize={12}
              lineHeight={16}
            >
              {data?.setup?.not_answered} puntos
            </Typography>
          </Container>
        </Container>

        <Container
          width='100%'
          direction='row'
          alignItems='center'
          justify='center'
          gap={24}
          mt={32}
        >
          <Button
            disabled={loading}
            type='border'
            width='40%'
            content='Lo haré más tarde'
            onClick={onClose}
          />
          <Button
            width='40%'
            loading={loading}
            onClick={() => {
              setLoading(true);
              onStart();
            }}
            content='Iniciar examen'
          />
        </Container>
      </Container>
    </Modal>
  );
};

export default ModalExamIndications;