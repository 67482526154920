import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Button, Col, Container, Grid, Message, Row, Typography } from 'components/atoms';
import { Form, ModalDisclaim as Modal } from 'components/molecules';
import { PublicLayout } from 'layouts';
import { userRegister } from 'util/request';
import { mapErrors } from 'util/functions';
import LoginImage from 'assets/images/login_image.png';
import Google from 'assets/images/social/google.svg';
import Facebook from 'assets/images/social/facebook.svg';

const Register = () => {
  const navigate = useNavigate();
  const registerMutation = useMutation(userRegister);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirm: '',
    non_field_errors: '',
  });
  const [showModal, setShowModal] = useState(false);

  const handleChangeForm = (text, field) => {
    setErrors({
      ...errors,
      [field]: '',
    });
    setForm({
      ...form,
      [field]: text,
    });
  };

  const handleRegister = (e) => {
    e.preventDefault();

    if (form.password !== passwordConfirm) {
      setErrors({
        ...errors,
        password_confirm: 'Las contraseñas no coinciden.'
      });
      return;
    }

    registerMutation.mutate(form, {
      onSuccess: () => {
        setShowModal(true);
      },
      onError: ({ response }) => {
        const { data } = response;
        const err = mapErrors(data.errors);
        setErrors(err);
      },
    });
  };

  return (
    <PublicLayout reverse imageUrl={LoginImage}>
      <Typography
        as='h2'
        color='primary'
        variant='dark'
        fontFamily='Sans'
        fontSize={40}
        fontWeight={700}
        mb={8}
      >
        ¡Bienvenido a SkillUp!
      </Typography>
      <Typography
        fontSize={16}
        lineHeight={24}
        textAlign='center'
        mb={32}
      >
        Únete a la nueva comunidad en búsqueda del éxito universitario
      </Typography>

      <Form onSubmit={handleRegister}>
        <Container>
          <Grid>
            <Row style={{ gap: 16 }}>
              <Col spaceless>
                <Form.Field
                  capitalize
                  label='Tus nombres'
                  name='firstname'
                  placeholder='Tu nombre'
                  value={form.first_name}
                  onChange={value => handleChangeForm(value, 'first_name')}
                  errorMessage={errors.first_name}
                />
              </Col>
              <Col spaceless>
                <Form.Field
                  capitalize
                  label='Tus apellidos'
                  name='lastname'
                  placeholder='Tus apellidos'
                  value={form.last_name}
                  onChange={value => handleChangeForm(value, 'last_name')}
                  errorMessage={errors.last_name}
                />
              </Col>
            </Row>
          </Grid>
        </Container>

        <Form.Field
          label='Correo electrónico'
          name='email'
          placeholder='Correo electrónico'
          value={form.email}
          onChange={value => handleChangeForm(value, 'email')}
          errorMessage={errors.email}
        />

        <Form.Field
          password
          label='Contraseña'
          name='password'
          placeholder='Al menos 8 caracteres'
          value={form.password}
          onChange={value => handleChangeForm(value, 'password')}
          errorMessage={errors.password}
        />

        <Form.Field
          password
          label='Confirmar contraseña'
          placeholder='Al menos 8 caracteres'
          value={passwordConfirm}
          onChange={value => setPasswordConfirm(value)}
          errorMessage={errors.password_confirm}
        />

        <Form.Button
          fluid
          loading={registerMutation.isLoading}
          onClick={handleRegister}
          content='Registrarme'
        />
      </Form>

      {errors.non_field_errors && (
        <Message type='warning' mt={16} textAlign='center'>
          {errors.non_field_errors}
        </Message>
      )}

      {/*<Container width='100%' mt={32}>*/}
      {/*  <Typography*/}
      {/*    fontSize={14}*/}
      {/*    lineHeight={16}*/}
      {/*    textAlign='center'*/}
      {/*  >*/}
      {/*    O también puedes registrarte con*/}
      {/*  </Typography>*/}

      {/*  <Container*/}
      {/*    width='100%'*/}
      {/*    direction='row'*/}
      {/*    gap={16}*/}
      {/*    mt={24}*/}
      {/*  >*/}
      {/*    <Button*/}
      {/*      fluid*/}
      {/*      type='border'*/}
      {/*      color='neutral'*/}
      {/*      onClick={() => {}}*/}
      {/*      content={*/}
      {/*        <Container*/}
      {/*          width='100%'*/}
      {/*          direction='row'*/}
      {/*          alignItems='center'*/}
      {/*          justify='center'*/}
      {/*          gap={16}*/}
      {/*        >*/}
      {/*          <img src={Google} style={{ width: 20, height: 20 }} alt='Google' />*/}
      {/*          <Typography*/}
      {/*            pointer*/}
      {/*            as='span'*/}
      {/*            color='neutral'*/}
      {/*            variant='darkest'*/}
      {/*            fontSize={16}*/}
      {/*            lineHeight={24}*/}
      {/*            fontWeight={700}*/}
      {/*          >*/}
      {/*            Google*/}
      {/*          </Typography>*/}
      {/*        </Container>*/}
      {/*      }*/}
      {/*    />*/}

      {/*    <Button*/}
      {/*      fluid*/}
      {/*      type='border'*/}
      {/*      color='neutral'*/}
      {/*      onClick={() => {}}*/}
      {/*      content={*/}
      {/*        <Container*/}
      {/*          width='100%'*/}
      {/*          direction='row'*/}
      {/*          alignItems='center'*/}
      {/*          justify='center'*/}
      {/*          gap={16}*/}
      {/*        >*/}
      {/*          <img src={Facebook} style={{ width: 20, height: 20 }} alt='Facebook' />*/}
      {/*          <Typography*/}
      {/*            pointer*/}
      {/*            as='span'*/}
      {/*            color='neutral'*/}
      {/*            variant='darkest'*/}
      {/*            fontSize={16}*/}
      {/*            lineHeight={24}*/}
      {/*            fontWeight={700}*/}
      {/*          >*/}
      {/*            Facebook*/}
      {/*          </Typography>*/}
      {/*        </Container>*/}
      {/*      }*/}
      {/*    />*/}
      {/*  </Container>*/}
      {/*</Container>*/}

      <Container width='100%' alignItems='center' mt={80}>
        <Typography
          as='span'
          color='neutral'
          fontSize={16}
          lineHeight={24}
        >
          ¿Ya tienes una cuenta? &nbsp;
          <Link to='/login'>
            <Typography
              pointer
              as='span'
              color='highlight'
              fontSize={16}
              lineHeight={24}
              fontWeight={600}
            >
              Inicia sesión aquí
            </Typography>
          </Link>
        </Typography>
      </Container>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={() => navigate('/login')}
        title='¡Te has registrado con éxito!'
        description='Gracias por registrarte, ahora formas parte de la comunidad Skillup y estás más cerca de cumplir tus metas.'
        btnConfirmText='Ir al login'
      />
    </PublicLayout>
    // <Container
    //   bgColor='background'
    //   minHeight='100vh'
    //   alignItems='center'
    //   justify='center'
    // >
    //   <Card
    //     maxWidth={578}
    //     paddingVertical={96}
    //     paddingHorizontal={48}
    //   >
    //     <Typography
    //       fontFamily='Outfit'
    //       fontWeight={700}
    //       fontSize={36}
    //       lineHeight={48}
    //       mb={16}
    //       textAlign='center'
    //     >
    //       Lets Register<br /> Account
    //     </Typography>
    //     <Typography
    //       fontFamily='Outfit'
    //       fontSize={28}
    //       lineHeight={36}
    //       mb={48}
    //       textAlign='center'
    //     >
    //       Hello user , you have<br />
    //       a greatful journey
    //     </Typography>
    //
    //     <form onSubmit={handleLogin}>
    //       <InputFloatLabel
    //         onChange={text => handleChangeForm(text, 'first_name')}
    //         label='Nombre'
    //         value={form.first_name}
    //         width={342}
    //         error={errors.first_name}
    //       />
    //
    //       <InputFloatLabel
    //         onChange={text => handleChangeForm(text, 'last_name')}
    //         label='Apellido'
    //         value={form.last_name}
    //         width={342}
    //         error={errors.last_name}
    //       />
    //
    //       <InputFloatLabel
    //         onChange={text => handleChangeForm(text, 'email')}
    //         label='Email'
    //         value={form.email}
    //         width={342}
    //         error={errors.email}
    //       />
    //
    //       <InputFloatLabel
    //         type='password'
    //         onChange={text => handleChangeForm(text, 'password')}
    //         label='Password'
    //         value={form.password}
    //         width={342}
    //         error={errors.password}
    //       />
    //
    //       <Container mt={24} mb={16}>
    //         <Button
    //           type='primary'
    //           onClick={handleLogin}
    //         >
    //           Registrar
    //         </Button>
    //       </Container>
    //     </form>
    //
    //
    //     <Typography
    //       as='p'
    //       textAlign='center'
    //       fontSize={16}
    //       lineHeight={24}
    //     >
    //       ¿Ya tienes una cuenta?&nbsp;
    //       <Link to='/login'>
    //         <Typography
    //           as='span'
    //           fontSize={16}
    //           fontWeight={700}
    //           lineHeight={24}
    //         >
    //           Ingresa aquí
    //         </Typography>
    //       </Link>
    //     </Typography>
    //   </Card>

    // </Container>
  );
};

export default Register;