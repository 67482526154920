import styled, { css } from 'styled-components';
import { containerWidth, media } from 'util/responsive';

const Grid = styled.div`
  width: 100%;
  
  ${props => props.container && css`
    width: auto;
    max-width: 100%;
    margin-left: 1em;
    margin-right: 1em;
    
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: ${containerWidth.sm}px;
      margin-left: auto;
      margin-right: auto;
    }
    
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: ${containerWidth.md}px;
      margin-left: auto;
      margin-right: auto;
    }
    
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      width: ${containerWidth.lg}px;
      margin-left: auto;
      margin-right: auto;
    }
    
    @media only screen and (min-width: 1600px) {
      width: ${containerWidth.xl}px;
      margin-left: auto;
      margin-right: auto;
    }
  `}
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${props => props.gap}px;
  align-items: ${props => props.alignItems || 'flex-start'};
  
  ${props => props.spaceless && `
    ${Col} {
      padding: 0 !important;
    }
  `}
`;

const Col = styled.div`
  flex: 1;
  padding: ${props => props.spaceless ? '0' : '0 1em'};
  ${props => props.xs && media['xs'](css`
    flex: 0 1 auto;
    ${(props.xs && props.xs !== 'none') ? 'display: block;' : 'display: none;'}
    width: calc((100% / 16) * ${props.xs});
  `)}
  ${props => props.sm && media['sm'](css`
    flex: 0 1 auto;
    ${(props.sm && props.sm !== 'none') ? 'display: block;' : 'display: none;'}
    width: calc((100% / 16) * ${props.sm});
  `)}
  ${props => props.md && media['md'](css`
    flex: 0 1 auto;
    ${(props.md && props.md !== 'none') ? 'display: block;' : 'display: none;'}
    width: calc((100% / 16) * ${props.md});
  `)}
  ${props => props.lg && media['lg'](css`
    flex: 0 1 auto;
    ${(props.lg && props.lg !== 'none') ? 'display: block;' : 'display: none;'}
    width: calc((100% / 16) * ${props.lg});
  `)}
`;

export { Grid, Col, Row };