import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Container, Typography } from 'components/atoms';
import { InputFloatLabel, ModalDisclaim as Modal } from 'components/molecules';
import { userCompleteRegister, userRegister } from 'util/request';
import { mapErrors } from 'util/functions';
import { useSelector } from 'react-redux';

const CompleteRegister = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.user);

  const [form, setForm] = useState({
    email: '',
  });
  const [errors, setErrors] = useState({
    email: false,
  });
  const [showModal, setShowModal] = useState(false);

  const handleChangeForm = (text, field) => {
    setErrors({
      ...errors,
      [field]: false,
    });
    setForm({
      ...form,
      [field]: text,
    });
  };

  const handleCompleteEmail = (e) => {
    e.preventDefault();

    setShowModal(true);

    userCompleteRegister({
      email: form.email,
      user_uuid: user.uuid,
    })
      .then(response => {
        const { status } = response;

        if (status === 200) {

        }
      })
      .catch(({ response }) => {
        const { data } = response;
        const err = mapErrors(data.errors);
        setErrors(err);
      });
  };

  return (
    <Container
      bgColor='background'
      minHeight='100vh'
      alignItems='center'
      justify='center'
    >
      <Card
        bgVariant='lightest'
        maxWidth={578}
        paddingVertical={96}
        paddingHorizontal={48}
      >
        <Typography
          fontWeight={700}
          fontSize={36}
          lineHeight={48}
          mb={16}
          textAlign='center'
        >
          Lets Complete<br /> Register
        </Typography>

        <form onSubmit={handleCompleteEmail}>
          <InputFloatLabel
            onChange={text => handleChangeForm(text, 'email')}
            label='Email'
            value={form.email}
            width={342}
            error={errors.email}
          />

          <Container mt={24} mb={16}>
            <Button
              type='primary'
              onClick={handleCompleteEmail}
            >
              Completar
            </Button>
          </Container>
        </form>

      </Card>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={() => {}}
        title='Te enviamos un email'
        description='Para continuar revisa tu correo y verifica tu cuenta desde el mensaje que te enviamos'
        btnConfirmText='¡Vamos!'
      />
    </Container>
  );
};

export default CompleteRegister;