import styled from 'styled-components';
import PropTypes from 'prop-types';

const Loader = styled.div`
  position: relative;
  background: conic-gradient(transparent, ${props => props.theme.colors.primary.medium});
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 2s linear infinite;
  
  &:before {
    content: '';
    position: absolute;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    background: white;
  }

  @keyframes spin {
    100% { transform: rotate(360deg); }
  }
`;

Loader.propTypes = {
  size: PropTypes.number,
};

Loader.defaultProps = {
  size: 64,
};

export default Loader;