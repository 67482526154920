import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Row, Col } from 'react-simple-flex-grid';
import { Button, Container, Skeleton, Typography } from 'components/atoms';
import { CardDashboard, Chart, FormField } from 'components/molecules';
import { getHistoricCourse, getHistoricGrade, getMyCourses, getProfile } from 'util/request';
import THEME from 'util/theme';
import { formatDate } from 'util/functions';
import Document from 'assets/images/svgs/document-normal.svg';

const initialFilter = [{
  id: '',
  label: 'Todos los cursos',
}];

const Dashboard = () => {
  const navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(true);
  const [courseFilter, setCourseFilter] = useState({
    id: '',
    label: 'Todos los cursos',
  });

  const userDataQuery = useQuery(
    ['me'],
    () => getProfile().then(({ data }) => data),
  );
  const coursesQuery = useQuery(
    ['myCourses'],
    () => getMyCourses().then(({ data }) => data),
    {
      placeholderData: [],
    },
  );
  const historicCourseQuery = useQuery(
    ['historicCourse', { course: courseFilter.id }],
    () => getHistoricCourse({
      page_size: 0,
      course_name: courseFilter.id,
    }).then(({ data }) => data),
    {
      placeholderData: [],
    },
  );
  const historicGradeQuery = useQuery(
    ['historicGrade'],
    () => getHistoricGrade({
      page_size: 0,
    }).then(({ data }) => data),
    {
      placeholderData: [],
    },
  );

  useEffect(() => {
    if (!historicCourseQuery.isLoading && !historicGradeQuery.isLoading && !userDataQuery.isLoading) {
      setInitialLoading(false);
    }
  }, [historicCourseQuery.isLoading, historicGradeQuery.isLoading, userDataQuery.isLoading]);

  const historicData = useMemo(() => {
    let coursesArr = [['Cargando', 0, 0, 0]];

    if (historicCourseQuery.data) {

      if (courseFilter.id) {
        coursesArr = historicCourseQuery.data.map(exam => {
          const good = exam.course_statistics[courseFilter.id].good;
          const bad = exam.course_statistics[courseFilter.id].bad;
          const notAnswered = exam.course_statistics[courseFilter.id].not_answered;

          return ([
            `${exam.year}-${exam.phase}`,
            good,
            bad,
            notAnswered,
          ])
        });
      } else {
        coursesArr = historicCourseQuery.data.map(exam => {
          let good = 0;
          let bad = 0;
          let notAnswered = 0;

          Object.keys(exam.course_statistics).forEach(key => {
            good += exam.course_statistics[key].good;
            bad += exam.course_statistics[key].bad;
            notAnswered += exam.course_statistics[key].not_answered;
          })

          return ([
            `${exam.institution_abbreviation}\n${exam.year}-${exam.phase}`,
            good,
            bad,
            notAnswered,
          ]);
        })
      }
    }

    if (coursesArr.length === 0) {
      coursesArr = [['Cargando', 0, 0, 0]];
    }

    return ([
      ['Examen', 'Correctas', 'Incorrectas', 'No respondidas'],
      ...coursesArr,
    ]);
  }, [historicCourseQuery.data])

  const gradeData = useMemo(() => {
    let gradeArr = [
      [0, 0],
    ]

    if (historicGradeQuery.data) {
      gradeArr = historicGradeQuery.data.map(exam => ([
        formatDate(exam.created_at),
        parseFloat(exam.grade),
      ]));
    }

    return ([
      ['Examen', 'Puntaje'],
      ...gradeArr,
    ]);
  }, [historicGradeQuery.data]);

  return (
    <Container width='100%'>
      {initialLoading ? (
        <Row gutter={16}>
          <Col xs={12} md={4}>
            <Skeleton width='100%' height={100} />
          </Col>
          <Col xs={12} md={4}>
            <Skeleton width='100%' height={100} />
          </Col>
          <Col xs={12} md={4}>
            <Skeleton width='100%' height={100} />
          </Col>
        </Row>
      ) : (
        <>
          {userDataQuery.data && userDataQuery.data.general_statistics && (
            <Row gutter={16}>
              <Col xs={12} md={4}>
                <CardDashboard
                  bgColor='positive'
                  bgVariant='medium'
                  textVariant='light'
                  title='Preguntas bien respondidas'
                  total={userDataQuery.data.general_statistics.questions.good}
                  quantity={userDataQuery.data.general_statistics.student_exam_quantity}
                />
              </Col>
              <Col xs={12} md={4}>
                <CardDashboard
                  bgColor='warning'
                  bgVariant='dark'
                  textVariant='light'
                  title='Preguntas mal respondidas'
                  total={userDataQuery.data.general_statistics.questions.bad}
                  quantity={userDataQuery.data.general_statistics.student_exam_quantity}
                />
              </Col>
              <Col xs={12} md={4}>
                <CardDashboard
                  bgColor='info'
                  bgVariant='dark'
                  textVariant='light'
                  title='Preguntas no respondidas'
                  total={userDataQuery.data.general_statistics.questions.not_answered}
                  quantity={userDataQuery.data.general_statistics.student_exam_quantity}
                />
              </Col>
            </Row>
          )}
        </>
      )}

      {initialLoading ? (
        <>
          <Skeleton width={200} height={24} mb={16} mt={16} />
          <Skeleton width='100%' height={300} />
        </>
      ) : (
        <>
          {(historicCourseQuery.data.length === 0 && historicGradeQuery.data.length === 0) ? (
            <Container
              width='100%'
              height={400}
              justify='center'
              alignItems='center'
            >
              <Container
                width={400}
                alignItems='center'
                gap={16}
              >
                <img src={Document} alt='Exámenes' />
                <Typography
                  color='neutral'
                  variant='light'
                  fontSize={16}
                  lineHeight={24}
                  textAlign='center'
                  mb={16}
                >
                  Aún no cuentas con datos de tus evaluaciones. Una vez que completes uno de los exámenes este aparecerá aquí.
                </Typography>
                <Button
                  onClick={() => navigate('/examenes/lista')}
                  content='Crear examen'
                />
              </Container>
            </Container>
          ) : (
            <>
              <Container width='100%' mt={32}>
                <Typography
                  as='h3'
                  color='primary'
                  variant='dark'
                  fontSize={20}
                  lineHeight={24}
                  fontWeight={700}
                >
                  Tus evaluaciones
                </Typography>

                <Container width='100%' mt={24}>
                  <Row>
                    <Col xs={12} md={{ span: 6, offset: 6 }}>
                      <FormField
                        search
                        disabled={coursesQuery.isLoading}
                        type='select'
                        placeholder='Curso'
                        options={initialFilter.concat(coursesQuery.data.map(key => ({
                          id: key.name,
                          label: key.name,
                        })))}
                        selected={courseFilter}
                        onSelect={value => setCourseFilter(value)}
                      />
                    </Col>
                  </Row>
                </Container>

                <Chart
                  type='ColumnChart'
                  height='600px'
                  data={historicData}
                  options={{
                    chartArea: {
                      left: 50,
                      right: 50,
                      top: 50,
                      bottom: 50,
                    },
                    colors: [THEME.colors.positive.medium, THEME.colors.warning.dark, THEME.colors.info.dark],
                    hAxis: {
                      textStyle: {
                        color: THEME.colors.neutral.medium,
                        fontSize: 14,
                      },
                    },
                  }}
                />
              </Container>

              <Container width='100%' mt={32}>
                <Typography
                  as='h3'
                  color='primary'
                  variant='dark'
                  fontSize={20}
                  lineHeight={24}
                  fontWeight={700}
                >
                  Tus puntajes
                </Typography>

                <Chart
                  type='LineChart'
                  height='600px'
                  data={gradeData}
                  options={{
                    chartArea: {
                      left: 50,
                      right: 50,
                      top: 50,
                    },
                    colors: [THEME.colors.highlight.medium, THEME.colors.info.dark],
                    hAxis: {
                      textStyle: {
                        color: THEME.colors.neutral.medium,
                        fontSize: 14,
                      },
                    },
                  }}
                />
              </Container>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Dashboard;