import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Typography } from 'components/atoms';
import { HEADER_HEIGHT, SIDE_MENU_WIDTH, menuOptions } from 'util/constants';
import Logo from 'assets/images/logo_white.svg';
import Logout from 'assets/images/svgs/logout.svg';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/session';

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  width: ${SIDE_MENU_WIDTH}px;
  height: 100vh;
  position: fixed;
  background-color: ${props => props.theme.colors.primary.dark};
  z-index: 100;
`;

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  
  img {
    height: 100%;
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const NavItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  
  a, > div {
    display: flex;
    flex: 1;
    position: relative;
    align-items: center;
    padding: 16px 24px;
    gap: 12px;
    border-radius: 16px;
    text-decoration: none;
    background-color: ${props => props.theme.colors.primary.dark};
    cursor: pointer;
    
    &.active {
      background-color: ${props => props.theme.colors.primary.medium};
    }
  }
  
  svg {
    font-size: 1.2em;
    path {
      fill: ${props => props.theme.colors.neutral.lightest};
    }
  }
`;

const ASide = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Wrapper>
      <LogoContainer>
        <img src={Logo} alt='Skillup logo' />
      </LogoContainer>

      <Container
        flex={1}
        width='100%'
        justify='space-between'
        style={{
          padding: '60px 32px 48px',
        }}
      >
        <NavContainer>
          {menuOptions.map(option => (
            <NavItem key={option.route}>
              <NavLink to={option.route}>
                <FontAwesomeIcon icon={option.icon} className='fa-fw' />
                <Typography
                  as='span'
                  color='neutral'
                  variant='lightest'
                  fontSize={16}
                  lineHeight={24}
                >
                  {option.label}
                </Typography>
              </NavLink>
            </NavItem>
          ))}
        </NavContainer>

        <Container>
          <NavItem>
            <div onClick={handleLogout}>
              <img src={Logout} alt='Cerrar sesión' />
              <Typography
                as='span'
                color='neutral'
                variant='lightest'
                fontSize={16}
                lineHeight={24}
              >
                Cerrar sesión
              </Typography>
            </div>
          </NavItem>
        </Container>
      </Container>
    </Wrapper>
  );
};

export default ASide;