import React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'util/responsive';
import PropTypes, { checkPropTypes } from 'prop-types';
import { colorValues, variantValues } from '../../../util/theme';

const Typography = styled(({ as, children, ...props }) => React.createElement(as, props, children))`
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.fontFamily === 'Sans' ? 'Source Sans 3' : props.fontFamily};
  text-align: ${props => props.textAlign};
  ${props => props.lineHeight ? `line-height: ${props.lineHeight}px` : ''};
  ${props => props.letterSpacing ? `letter-spacing: ${props.letterSpacing}px` : ''};
  ${props => props.fontStyle ? `font-style: ${props.fontStyle}` : ''};
  color: ${props => props.color ? props.theme.colors[props.color][props.variant] : props.theme.colors.textBlack};
  cursor: ${props => (props.onClick || props.pointer) ? 'pointer' : 'inherit'};
  margin: 0;
  ${props => props.mb ? `margin-bottom: ${props.mb}px;` : ''}
  ${props => props.mt ? `margin-top: ${props.mt}px;` : ''}
  ${props => props.mr ? `margin-right: ${props.mr}px;` : ''}
  ${props => props.ml ? `margin-left: ${props.ml}px;` : ''}
  padding: 0;
  ${props => props.pb ? `padding-bottom: ${props.pb}px;` : ''}
  ${props => props.pt ? `padding-top: ${props.pt}px;` : ''}
  ${props => props.pr ? `padding-right: ${props.pr}px;` : ''}
  ${props => props.pl ? `padding-left: ${props.pl}px;` : ''}

  ${props => props.xs && media['xs'](`
    ${props.xs.mb ? `margin-bottom: ${props.xs.mb}px;` : ''}
    ${props.xs.mt ? `margin-top: ${props.xs.mt}px;` : ''}
    ${props.xs.ml ? `margin-left: ${props.xs.ml}px;` : ''}
    ${props.xs.mr ? `margin-right: ${props.xs.mr}px;` : ''}
    ${props.xs.pb ? `padding-bottom: ${props.xs.pb}px;` : ''}
    ${props.xs.pt ? `padding-top: ${props.xs.pt}px;` : ''}
    ${props.xs.pl ? `padding-left: ${props.xs.pl}px;` : ''}
    ${props.xs.pr ? `padding-right: ${props.xs.pr}px;` : ''}
  `)}
  ${props => props.sm && media['sm'](`
    ${props.sm.mb ? `margin-bottom: ${props.sm.mb}px;` : ''}
    ${props.sm.mt ? `margin-top: ${props.sm.mt}px;` : ''}
    ${props.sm.ml ? `margin-left: ${props.sm.ml}px;` : ''}
    ${props.sm.mr ? `margin-right: ${props.sm.mr}px;` : ''}
    ${props.sm.pb ? `padding-bottom: ${props.sm.pb}px;` : ''}
    ${props.sm.pt ? `padding-top: ${props.sm.pt}px;` : ''}
    ${props.sm.pl ? `padding-left: ${props.sm.pl}px;` : ''}
    ${props.sm.pr ? `padding-right: ${props.sm.pr}px;` : ''}
  `)}
  ${props => props.md && media['md'](`
    ${props.md.mb ? `margin-bottom: ${props.md.mb}px;` : ''}
    ${props.md.mt ? `margin-top: ${props.md.mt}px;` : ''}
    ${props.md.ml ? `margin-left: ${props.md.ml}px;` : ''}
    ${props.md.mr ? `margin-right: ${props.md.mr}px;` : ''}
    ${props.md.pb ? `padding-bottom: ${props.md.pb}px;` : ''}
    ${props.md.pt ? `padding-top: ${props.md.pt}px;` : ''}
    ${props.md.pl ? `padding-left: ${props.md.pl}px;` : ''}
    ${props.md.pr ? `padding-right: ${props.md.pr}px;` : ''}
  `)}
  ${props => props.lg && media['lg'](`
    ${props.lg.mb ? `margin-bottom: ${props.lg.mb}px;` : ''}
    ${props.lg.mt ? `margin-top: ${props.lg.mt}px;` : ''}
    ${props.lg.ml ? `margin-left: ${props.lg.ml}px;` : ''}
    ${props.lg.mr ? `margin-right: ${props.lg.mr}px;` : ''}
    ${props.lg.pb ? `padding-bottom: ${props.lg.pb}px;` : ''}
    ${props.lg.pt ? `padding-top: ${props.lg.pt}px;` : ''}
    ${props.lg.pl ? `padding-left: ${props.lg.pl}px;` : ''}
    ${props.lg.pr ? `padding-right: ${props.lg.pr}px;` : ''}
  `)}
`;

Typography.propTypes = {
  as: PropTypes.oneOf(['span', 'p', 'label', 'h1', 'h2', 'h3', 'h4', 'h5']),
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(colorValues),
  variant: PropTypes.oneOf(variantValues),
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontFamily: PropTypes.oneOf(['Sora', 'Sans']),
  textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  pointer: PropTypes.bool,
  onClick: PropTypes.func,
  lines: PropTypes.number,
};

Typography.defaultProps = {
  as: 'p',
  color: 'textBlack',
  variant: 'medium',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 'inherit',
  fontFamily: 'Sora',
  textAlign: 'left',
  pointer: false,
  onClick: undefined,
  lines: undefined,
};

export default Typography;
