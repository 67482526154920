import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/atoms/index';

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 0 24px 8px;
  padding: 12px 24px;
  border-radius: 16px;
  background-color: ${props => props.isCorrect ? props.theme.colors.positive.medium : (props.isCheck ? props.theme.colors.highlight.medium : props.theme.colors.primary.lightest)};
  cursor: ${props => props.pointer ? 'pointer' : 'default'};
`;

const Key = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.neutral.darkest};
  border: 1px solid ${props => props.theme.colors.primary.medium};
  background-color: ${props => props.theme.colors.grayscale.light};
`;

const Text = styled.div`
  flex: 1;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.checked || props.isCorrect) ? props.theme.colors.grayscale.light : props.theme.colors.neutral.medium};
  
  p {
    margin: 0;
  }
  * {
    color: ${props => (props.checked || props.isCorrect) ? props.theme.colors.grayscale.light : props.theme.colors.neutral.medium};
  }
`;

const Alternative = ({ onlyRead, data, check, onCheck }) => {
  return (
    <Wrapper
      pointer={!onlyRead}
      isCheck={check}
      isCorrect={onlyRead && data.is_correct}
      onClick={onCheck}
    >
      <Key
        checked={check}
      >
        {data.alternative}
      </Key>
      <Text
        checked={check}
        isCorrect={onlyRead && data.is_correct}
        dangerouslySetInnerHTML={{ __html: data.description }}
      />
    </Wrapper>
  )
};

export default Alternative;