import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Grid, Row, Typography } from 'components/atoms';
import { phaseDisplay } from 'util/constants';

const ExamRow = ({
  data,
  loading,
  onStart,
}) => (
  <Card bgVariant='lightest' width='100%' padding={8}>
    <Grid>
      <Row alignItems='center'>
        <Col sm={4}>
          <Typography>
            {data.institution.abbreviation}
          </Typography>
        </Col>
        <Col sm={6}>
          <Typography>
            {data.description}
          </Typography>
        </Col>
        <Col sm={2}>
          <Typography>
            {data.year} - {data.phase}
          </Typography>
        </Col>
        <Col sm={4}>
          <Button
            loading={loading}
            width='100%'
            weight={400}
            content='Crear examen'
            onClick={onStart}
          />
        </Col>
      </Row>
    </Grid>
  </Card>
);

ExamRow.propTypes = {
  data: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  onStart: PropTypes.func.isRequired,
};

ExamRow.defaultProps = {
  loading: false,
};

export default ExamRow;