import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container } from 'components/atoms';

const Tab = styled.div`
  flex: 1;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: ${props => props.active ? props.theme.colors.primary : props.theme.colors.white};
  color: ${props => props.active ? props.theme.colors.white : props.theme.colors.primary};
`;

const Tabs = ({ items, onClick, active }) => {
  return (
    <Container
      direction='row'
      width='100%'
      alignItems='center'
    >
      {items.map(item => (
        <Tab
          active={active === item.id}
          key={item.id}
          onClick={() => {
            if (onClick)
              onClick(item.id)
          }}
        >
          {item.label}
        </Tab>
      ))}
    </Container>
  );
};

Tabs.propTypes = {
  active: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  })).isRequired,
  onClick: PropTypes.func,
};

export default Tabs;