import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Row, Col } from 'react-simple-flex-grid';
import { useNavigate } from 'react-router-dom';
import { Container, Message, Typography } from 'components/atoms';
import { Form } from 'components/molecules';
import { login, refreshData } from 'store/session';
import { changeCareer, getCareers, getInstitutions } from 'util/request';

const ChangeCareer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [selectedCareer, setSelectedCareer] = useState(null);
  const [errors, setErrors] = useState({
    institution: '',
    career: '',
  });

  const institutionsQuery = useQuery(
    ['institutions'],
    () => getInstitutions({ page_size: 0 }),
  );
  const careerQuery = useQuery(
    ['careers'],
    () => getCareers({
      page_size: 0,
      institution: selectedInstitution.id,
    }),
    {
      cacheTime: 0,
      enabled: false,
    },
  );
  const careerMutation = useMutation(changeCareer);

  useEffect(() => {
    if (selectedInstitution) {
      setSelectedCareer(null);
      careerQuery.refetch();
    }
  }, [selectedInstitution]);

  const handleChangeCareer = e => {
    e.preventDefault();

    let valid = true;
    const err = {
      institution: '',
      career: '',
    };

    if (!selectedInstitution) {
      err.institution = 'Debes seleccionar una institución';
      valid = false;
    }

    if (!selectedCareer) {
      err.career = 'Debes seleccionar una carrera';
      valid = false;
    }

    setErrors(err);

    if (!valid) return;

    careerMutation.mutate({
      institution_career: selectedCareer.id,
    }, {
      onSuccess: () => {
        toast.success('La carrera se cambio satisfactoriamente');
        dispatch(refreshData());
        // navigate('/mi-perfil');
      }
    })
  };

  return (
    <Container width='100%'>
      <Typography
        as='h2'
        fontFamily='Sans'
        color='primary'
        variant='darkest'
        fontSize={24}
        lineHeight={40}
        fontWeight={600}
        mb={32}
      >
        Cambiar carrera
      </Typography>

      <Container width='100%'>
        <Row>
          <Col spaceless xs={12} sm={8}>
            <Form>
              <Form.Field
                search
                type='select'
                label='Institución'
                loading={institutionsQuery.isLoading}
                placeholder='Selecciona la institución'
                selected={selectedInstitution}
                onSelect={value => setSelectedInstitution(value)}
                options={institutionsQuery.data?.data.map(item => ({
                  id: item.uuid,
                  label: item.name,
                }))}
                errorMessage={errors.institution}
              />

              <Form.Field
                search
                type='select'
                label='Carrera'
                disabled={!careerQuery.data}
                loading={careerQuery.isFetching}
                placeholder='Selecciona la carrera'
                selected={selectedCareer}
                onSelect={value => setSelectedCareer(value)}
                options={careerQuery.data?.data.map(item => ({
                  id: item.uuid,
                  label: item.career.name,
                }))}
                errorMessage={errors.career}
              />

              <Form.Button
                loading={careerMutation.isLoading}
                content='Cambiar carrera'
                onClick={handleChangeCareer}
              />
            </Form>

            {errors.non_field_errors && (
              <Message type='warning' mt={16} textAlign='center'>
                {errors.non_field_errors}
              </Message>
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ChangeCareer;