import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { colorValues, variantValues } from '../../../util/theme';

const Icon = styled(FontAwesomeIcon)`
  color: ${props => props.color ? props.theme.colors[props.color][props.variant] : props.theme.colors.textBlack};
  font-size: ${props => props.fontSize}px;
`;

Icon.propTypes = {
  icon: PropTypes.any.isRequired,
  color: PropTypes.oneOf(colorValues),
  variant: PropTypes.oneOf(variantValues),
  fontSize: PropTypes.number,
};

Icon.defaultProps = {
  color: 'neutral',
  variant: 'medium',
  fontSize: undefined,
};

export default Icon;