import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { media } from 'util/responsive';

const Background = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  backdrop-filter: blur(4px);
  background: rgba(73, 80, 102, 0.8);
  animation: ${props => props.animateIn ? 'fadeIn' : 'fadeOut'} 0.4s cubic-bezier(0.4, 0.575, 0.565, 1) both;

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 48px 32px;
  border-radius: 16px;
  animation: ${props => props.animateIn ? 'scaleCenter' : 'scaleOut'} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  max-width: 90%;
  
  > svg {
    position: absolute;
    font-size: 24px;
    cursor: pointer;
    top: 12px;
    right: 12px;
    
    path {
      fill: ${props => props.theme.colors.primary};
    }
  }

  ${media.md(props => css`
    max-width: ${props.maxWidth}px;
  `)};
  
  @keyframes scaleCenter {
    0% { transform: translateY(-80px); }
    100% { transform: translateY(-40px); }
  }
  
  @keyframes scaleOut {
    0% { transform: translateY(-40px); }
    100% { transform: translateY(-80px); }
  }
`;

const Modal = ({
  show,
  maxWidth,
  onClose,
  showCloseIcon,
  overlayClose,
  children,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setVisible(true);
    }

    if (!show && visible) {
      setTimeout(() => {
        setVisible(false);
      }, 400);
    }
  }, [show, visible]);

  const handleOverlayClick = () => {
    if (overlayClose) {
      onClose();
    }
  };

  if (!visible) return null;

  return createPortal(
    <Background animateIn={show} onClick={handleOverlayClick}>
      <ModalContent
        animateIn={show}
        maxWidth={maxWidth}
        onClick={e => e.stopPropagation()}
      >
        {showCloseIcon && (
          <FontAwesomeIcon icon={faCircleXmark} onClick={onClose} />
        )}
        {children}
      </ModalContent>
    </Background>,
    document.getElementById('modal-root')
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  maxWidth: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  showCloseIcon: PropTypes.bool,
  overlayClose: PropTypes.bool,
};

Modal.defaultProps = {
  showCloseIcon: false,
  overlayClose: false,
  maxWidth: 500,
};

export default Modal;