import styled from 'styled-components';
import PropTypes from 'prop-types';

const paddingIcon = {
  left: '12px 24px 12px 48px',
  right: '12px 64px 12px 24px',
};

const Input = styled.input(props => ({
  height: 48,
  width: '100%',
  borderRadius: 16,
  backgroundColor: props.theme.colors.white,
  border: `1px solid ${props.error ? props.theme.colors.warning.dark : props.theme.colors.primary.medium}`,
  padding: props.hasIcon ? paddingIcon[props.iconPosition] : '12px 24px',
  fontSize: 16,
  lineHeight: 24,
  color: props.theme.colors.neutral.darkest,
  '&::placeholder': {
    color: props.theme.colors.neutral.light,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 400,
  },
  '&:disabled': {
    color: '#9C9C9C', // TODO: change to theme const
    backgroundColor: '#F8F8F8',
    '&::placeholder': {
      color: '#9C9C9C',
    }
  },
  '&:focus': {
    outline: 'none',
    border: `1px solid ${props.error ? props.theme.colors.error : props.theme.colors.inputFocus}`,
  },
}));

Input.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hasIcon: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
};

Input.defaultProps = {
  disabled: false,
  error: false,
  hasIcon: false,
  iconPosition: 'left',
};

export default Input;