import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from 'components/atoms';
import FormField from 'components/molecules/FormField';

const CustomForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  button {
    margin-top: 16px;
  }
`;

const Form = ({ children, onSubmit }) => {
  const handleSubmit = e => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  }

  return (
    <CustomForm onSubmit={handleSubmit}>
      {children}
    </CustomForm>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  onSubmit: undefined,
};

Form.Field = FormField;
Form.Button = Button;

export default Form;