import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'util/axios';
import { mapErrors } from 'util/functions';
import { processStatus } from 'util/constants';

export const getStatement = createAsyncThunk('examProcess/getExam', async (id, { rejectWithValue }) => {
  try {
    const response = await axios.get('/student/statements', {
      params: {
        student_exam_uuid: id,
        page_size: 0,
      }
    });

    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const startProcess = createAsyncThunk('examProcess/start', async (arg, { getState }) => {
  const state = getState();

  try {
    const response = await axios.get(`/student/student-exams/${state.examProcess.uuid}/start`);

    return response.data;
  } catch (err) {
    throw err;
  }
});

export const sendAnswer = createAsyncThunk('examProcess/answer', async (data, { getState }) => {
  const state = getState();

  try {
    const response = await axios.patch(`/student/student-exams/${state.examProcess.uuid}/update-answers`, {
      answers: [data],
    });

    return response.data;
  } catch (err) {
    throw err;
  }
});

export const finishProcess = createAsyncThunk('examProcess/finish', async (arg, { getState }) => {
  const state = getState();
  const data = {
    finish: true,
    answers: state.examProcess.answers,
  };

  try {
    const response = await axios.patch(`/student/student-exams/${state.examProcess.uuid}/update-answers`, data);

    return response.data;
  } catch (err) {
    throw err;
  }
});

const initialState = {
  uuid: null,
  examData: {},
  status: undefined,
  grade: undefined,
  statement: [],
  answers: [],
  sideOptions: {},
  loading: false,
  loadingFinish: false,
  startAt: undefined,
  focus: undefined,
};

const examProcessSlide = createSlice({
  name: 'examProcess',
  initialState: initialState,
  reducers: {
    setId(state, action) {
      state.uuid = action.payload;
    },
    setExamData(state, action) {
      state.examData = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setGrade(state, action) {
      state.grade = action.payload;
    },
    setStartTime(state, action) {
      state.startAt = new Date(action.payload);
    },
    setFocusOption(state, action) {
      state.focus = action.payload;
    },
    storageAnswer(state, action) {
      const index = state.answers.findIndex(item => item.answer === action.payload.answer);
      const storageData = {
        answer: action.payload.answer,
        alternative: action.payload.alternative,
      };

      if (index !== -1) {
        state.answers[index] = storageData;
      } else {
        state.answers = state.answers.concat(storageData);
      }

      if (!action.payload.alreadyAnswered && action.payload.alternative) {
        state.sideOptions[action.payload.subject].answered += 1;
      } else if (action.payload.alreadyAnswered && !action.payload.alternative) {
        state.sideOptions[action.payload.subject].answered -= 1;
      }
    },
    resetProcess(state) {
      state.uuid = null;
      state.status = undefined;
      state.statement = [];
      state.answers = [];
      state.loadingFinish = false;
    },
  },
  extraReducers: builder => {
    // get exam
    builder.addCase(getStatement.pending, state => {
      state.statement = [];
      state.answers = [];
      state.loading = true;
    });
    builder.addCase(getStatement.fulfilled, (state, action) => {
      const arr = [];
      const obj = {};
      action.payload.forEach(item => {
        let answered = 0;
        item.questions.forEach(question => {
          if (question.answers[0].alternative) {
            answered += 1;
          }
          arr.push({
            answer: question.answers[0].uuid,
            alternative: question.answers[0].alternative,
          })
        })

        if (obj[item.course.name]) {
          obj[item.course.name] = {
            total: obj[item.course.name].total + item.questions.length,
            answered: obj[item.course.name].answered + answered,
          }
        } else {
          obj[item.course.name] = {
            total: item.questions.length,
            answered: answered,
          };
        }

      });
      state.answers = arr;
      state.sideOptions = obj;
      state.statement = action.payload;
      state.loading = false;
    });
    builder.addCase(getStatement.rejected, (state, action) => {
      state.loading = false;
      state.errors = mapErrors(action.payload.errors);
    });
    // start exam
    builder.addCase(startProcess.fulfilled, (state, action) => {
      state.status = processStatus.STARTED;
      console.log(action.payload);
      state.startAt = new Date(action.payload.started_at);
    });
    builder.addCase(startProcess.rejected, (state, action) => {
      state.loading = false;
      state.errors = mapErrors(action.payload.errors);
    });
    // send answers
    builder.addCase(sendAnswer.fulfilled, () => {
      console.log('RESPUESTA ENVIADA')
    });
    builder.addCase(sendAnswer.rejected, (state, action) => {
      console.log('RESPUESTA ERROR')
    });
    // finish exam
    builder.addCase(finishProcess.pending, state => {
      state.loadingFinish = true;
    });
    builder.addCase(finishProcess.fulfilled, (state, action) => {
      state.uuid = null;
      state.status = processStatus.TERMINATED;
      state.statement = [];
      state.answers = [];
      state.loadingFinish = false;
    });
    builder.addCase(finishProcess.rejected, (state, action) => {
      state.loadingFinish = false;
      state.errors = mapErrors(action.payload.errors);
    });
  },
});

const { actions, reducer } = examProcessSlide;

export const {
  storageAnswer,
  setId,
  setStatus,
  setGrade,
  setFocusOption,
  setExamData,
  setStartTime,
  resetProcess,
} = actions;

export default reducer;
