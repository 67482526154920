import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Button, Card, Container, Loader, Typography } from 'components/atoms';
import { FullPageLayout } from 'layouts';
import { userVerifyAccount } from 'util/request';
import BackgroundImage from 'assets/images/background_image.png';
import SuccessImage from 'assets/images/ilustrations/success_verification.svg';

const VerifyAccount = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const verificationMutation = useMutation(userVerifyAccount);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const tk = searchParams.get('tk');

      if (tk) {
        verificationMutation.mutate({
          token: tk,
        });
      }
    }, 100)

    return () => clearTimeout(timeout);
  }, [searchParams]);

  return (
    <FullPageLayout image={BackgroundImage}>
      <Container alignItems='center' width={552} gap={16}>
        {verificationMutation.isLoading && (
          <>
            <Container width='100%' alignItems='center'>
              <Loader/>
            </Container>
            <Typography
              color='neutral'
              fontSize={16}
              lineHeight={24}
              textAlign='center'
            >
              Estamos realizando la activación de tu cuenta. Esto puede tomar un momento.
            </Typography>
          </>
        )}
        {verificationMutation.isSuccess && (
          <>
            <Container width='100%' alignItems='center'>
              <img src={SuccessImage} alt='Cuenta verificada'/>
            </Container>
            <Typography
              as='h2'
              color='primary'
              fontSize={32}
              lineHeight={40}
              fontWeight={700}
              textAlign='center'
            >
              ¡Te has registrado con éxito!
            </Typography>
            <Typography
              color='neutral'
              fontSize={16}
              lineHeight={24}
              textAlign='center'
            >
              Te damos la bienvenida a nuestra plataforma en la cual podrás prepararte de la manera más adecuada para
              ti y la meta que estás buscando conseguir.
            </Typography>

            <Button
              fluid
              onClick={() => navigate('/login')}
              content='Ir al login'
            />
          </>
        )}
        {verificationMutation.isError && (
          <>
            <Typography
              as='h2'
              color='primary'
              fontSize={32}
              lineHeight={40}
              fontWeight={700}
              textAlign='center'
            >
              Ocurrio un error
            </Typography>
            <Typography
              color='neutral'
              fontSize={16}
              lineHeight={24}
              textAlign='center'
            >
              Hubo un error al verificar tu cuenta, la URL es incorrecta o ha caducado.
            </Typography>
          </>
        )}
      </Container>
    </FullPageLayout>
  );
};

export default VerifyAccount;