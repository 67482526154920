import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Container, Typography } from 'components/atoms';
import { resetProcess, startProcess } from 'store/examProcess';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getExamIndications } from '../../../../util/request';
import { LayoutProcess } from '../../../../layouts';
import { ModalExamIndications } from '../../../../components/organisms';
import { useNavigate } from 'react-router-dom';

const Created = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const examProcess = useSelector(state => state.examProcess);

  const handleStartExam = () => {
    dispatch(startProcess());
  };

  const handleGoBack = () => {
    dispatch(resetProcess());
    navigate(-1);
  };

  return (
    <LayoutProcess>
      <ModalExamIndications
        show
        onClose={handleGoBack}
        onStart={handleStartExam}
        data={examProcess.examData}
      />
    </LayoutProcess>
  );
};

export default Created;