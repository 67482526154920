import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Container, Typography } from 'components/atoms';
import { setStatus, startProcess } from 'store/examProcess';
import { useNavigate } from 'react-router-dom';
import { FullPageLayout } from 'layouts';
import Winners from 'assets/images/ilustrations/winner.svg';
import ExamBackground from 'assets/images/exam_background.png';

const Finished = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoToHistory = () => {
    dispatch(setStatus(undefined));
    navigate('/mis-examenes');
  };

  return (
    <FullPageLayout image={ExamBackground}>
      <Container width={528} height='100%' alignItems='center'>
        <img src={Winners} alt='Hecho' />

        <Typography
          as='h3'
          fontFamily='Sans'
          color='positive'
          fontSize={32}
          lineHeight={40}
          textAlign='center'
          mt={24}
          mb={24}
        >
          Examen Finalizado
        </Typography>
        <Typography
          color='neutral'
          variant='dark'
          fontSize={16}
          lineHeight={24}
          textAlign='center'
          mb={24}
        >
          Haz hecho un gran trabajo, en unos momentos podrás visualizar la nota alcanzada en tu examen.
        </Typography>

        <Container width='100%' gap={16}>
          <Button
            width='100%'
            onClick={handleGoToHistory}
            content='Ir a mi historial de exámenes'
            mb={16}
          />

          <Button
            type='border'
            width='100%'
            onClick={() => {}}
            content={
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={process.env.REACT_APP_END_EXAM_FORM_URL}
              >
                Cuentanos tu experiencia
              </a>
            }
          />
        </Container>
      </Container>
    </FullPageLayout>
  );
};

export default Finished;