import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-simple-flex-grid';
import { useQuery } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faClone } from '@fortawesome/free-regular-svg-icons';
import { Card, Container, Typography } from 'components/atoms';
import { FormField } from 'components/molecules';
import { DataTable } from 'components/organisms';
import { getRankingByCareer, getRankingByInstitution, getYearsPhases } from 'util/request';
import { capitalize } from 'util/functions';
import { phaseDisplay } from 'util/constants';
import Chart from 'assets/images/svgs/chart.svg';

const Ranking = () => {
  const session = useSelector(state => state.session);
  const [instFilter, setInstFilter] = useState({
    year: undefined,
    phase: undefined,
  });
  const [careerFilter, setCareerFilter] = useState({
    year: undefined,
    phase: undefined,
  });

  const institutionName = useMemo(() => session.user.student_data.institution_career.institution.name, [session]);
  const careerName = useMemo(() => session.user.student_data.institution_career.career.name, [session]);


  const filterQuery = useQuery(
    ['dataPhase'],
    () => getYearsPhases()
      .then(({ data }) => data),
    {
      placeholderData: [],
    },
  );
  const institutionQuery = useQuery(
    ['rankingInstitution', { phase: instFilter.phase?.id, year: instFilter.year?.id }],
    () => getRankingByInstitution({
      year: instFilter.year?.id,
      phase: instFilter.phase?.id,
    }).then(({ data }) => data), {
      placeholderData: { results: [] },
      enabled: !!instFilter.phase?.id && !!instFilter.year?.id,
    }
  );
  const careerQuery = useQuery(
    ['rankingCareer', { phase: careerFilter.phase?.id, year: careerFilter.year?.id }],
    () => getRankingByCareer({
      year: careerFilter.year?.id,
      phase: careerFilter.phase?.id,
    }).then(({ data }) => data), {
      placeholderData: { results: [] },
      enabled: !!careerFilter.phase?.id && !!careerFilter.year?.id,
    }
  );

  const instHeaders = useMemo(() => ([
    {
      id: 'rank',
      label: 'Puesto',
      cell: item => item.ranking,
    },
    {
      id: 'grade',
      label: 'Puntaje obtenido',
      cell: item => item.grade,
    },
    {
      id: 'user',
      label: 'Nombre',
      cell: item => capitalize(item.student_name),
    },
    {
      id: 'career',
      label: 'Carrera',
      cell: item => capitalize(item.career_name),
    },
    {
      id: 'times',
      label: 'Repeticiones',
      cell: item => item.times,
    },
  ]), []);

  const careerHeaders = useMemo(() => ([
    {
      id: 'rank',
      label: 'Puesto',
      cell: (item, index) => index + 1,
    },
    {
      id: 'grade',
      label: 'Puntaje obtenido',
      cell: item => item.grade,
    },
    {
      id: 'user',
      label: 'Nombre',
      cell: item => capitalize(item.student_name),
    },
    {
      id: 'times',
      label: 'Repeticiones',
      cell: item => item.times,
    },
  ]), []);

  return (
    <Container width='100%'>
      <Typography
        as='h2'
        fontFamily='Sans'
        color='primary'
        variant='darkest'
        fontSize={24}
        lineHeight={40}
        fontWeight={600}
        mb={32}
      >
        Ranking
      </Typography>

      <Container width='100%' mb={32}>
        <Card
          width='100%'
          bgVariant='light'
          padding='32px 60px'
        >
          <Typography
            as='h3'
            color='primary'
            variant='dark'
            fontSize={24}
            lineHeight={40}
            fontWeight={600}
            mb={24}
          >
            General {capitalize(institutionName)}
          </Typography>

          <Container width='100%' mb={32}>
            <Row gutter={32}>
              <Col xs={12} sm={4}>
                <FormField
                  disabled={filterQuery.isLoading}
                  type='select'
                  placeholder='Año'
                  options={Object.keys(filterQuery.data).map(key => ({
                    id: key,
                    label: key,
                  }))}
                  selected={instFilter.year}
                  onSelect={value => setInstFilter({
                    ...instFilter,
                    year: value,
                  })}
                  icon={<FontAwesomeIcon icon={faCalendarCheck} />}
                />
              </Col>
              <Col xs={12} sm={4}>
                <FormField
                  disabled={filterQuery.isLoading || !instFilter.year?.id}
                  type='select'
                  placeholder='Fase'
                  options={instFilter.year?.id ? filterQuery.data[instFilter.year?.id].map(phase => ({
                    id: phase,
                    label: phase,
                  })) : []}
                  selected={instFilter.phase}
                  onSelect={value => setInstFilter({
                    ...instFilter,
                    phase: value,
                  })}
                  icon={<FontAwesomeIcon icon={faClone} />}
                />
              </Col>
            </Row>
          </Container>

          {(instFilter.year?.id && instFilter.phase?.id) ? (
            <DataTable
              headers={instHeaders}
              data={institutionQuery.data.results}
            />
          ) : (
            <Container width='100%' alignItems='center'>
              <img src={Chart} alt='Ranking' />
              <Typography
                mt={16}
                textAlign='center'
              >
                Para visualizar el ranking tienes que seleccionar un año y fase.
              </Typography>
            </Container>
          )}
        </Card>
      </Container>

      <Container width='100%'>
        <Card
          width='100%'
          bgVariant='light'
          padding='32px 60px'
        >
          <Typography
            as='h3'
            color='primary'
            variant='dark'
            fontSize={24}
            lineHeight={40}
            fontWeight={600}
            mb={32}
          >
            Carrera {capitalize(careerName)}
          </Typography>

          <Container width='100%' mb={32}>
            <Row gutter={32}>
              <Col xs={12} sm={4}>
                <FormField
                  disabled={filterQuery.isLoading}
                  type='select'
                  placeholder='Año'
                  options={Object.keys(filterQuery.data).map(key => ({
                    id: key,
                    label: key,
                  }))}
                  selected={careerFilter.year}
                  onSelect={value => setCareerFilter({
                    ...careerFilter,
                    year: value,
                  })}
                  icon={<FontAwesomeIcon icon={faCalendarCheck} />}
                />
              </Col>
              <Col xs={12} sm={4}>
                <FormField
                  disabled={filterQuery.isLoading || !careerFilter.year?.id}
                  type='select'
                  placeholder='Fase'
                  options={careerFilter.year?.id ? filterQuery.data[careerFilter.year?.id].map(phase => ({
                    id: phase,
                    label: phase,
                  })) : []}
                  selected={careerFilter.phase}
                  onSelect={value => setCareerFilter({
                    ...careerFilter,
                    phase: value,
                  })}
                  icon={<FontAwesomeIcon icon={faClone} />}
                />
              </Col>
            </Row>
          </Container>

          {(careerFilter.year?.id && careerFilter.phase?.id) ? (
            <DataTable
              headers={careerHeaders}
              data={careerQuery.data.results}
            />
          ) : (
            <Container width='100%' alignItems='center'>
              <img src={Chart} alt='Ranking' />
              <Typography
                mt={16}
                textAlign='center'
              >
                Para visualizar el ranking tienes que seleccionar un año y fase.
              </Typography>
            </Container>
          )}
        </Card>
      </Container>

    </Container>
  );
};

export default Ranking;
