export const mapErrors = errorObj => {
  const err = {};
  errorObj.forEach(item => {
    if (item.attr) {
      err[item.attr] = item.detail;
    } else {
      err.non_field_errors = item.detail;
    }
  });

  return err;
};

export const convertHexToRGBA = (hexCode, opacity = 1) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const capitalize = val => {
  const str = val.split(' ');
  return str.map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(' ');
};

export const formatDate = (date, format = 'dd/mm/aaaa') => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();

  return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
};

export const secondsFormatter = (seconds, format = 'm') => {
  if (seconds) {
    switch (format) {
      case 'm':
        return seconds/60;
    }
  } else {
    return 0;
  }
};