import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Button, Card, Container, Input, Loader, Typography } from 'components/atoms';
import { FullPageLayout } from 'layouts';
import { userResetPassword, userVerifyRecovery } from 'util/request';
import BackgroundImage from 'assets/images/background_image.png';
import ForgotImage from 'assets/images/ilustrations/forgot_password.svg';
import SuccessImage from 'assets/images/ilustrations/success_verification.svg';
import { Form } from '../../../components/molecules';

const VerifyAccount = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const verificationMutation = useMutation(userVerifyRecovery);
  const resetMutation = useMutation(userResetPassword);
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const timeout = setTimeout(() => {
      const tk = searchParams.get('tk');

      if (tk) {
        verificationMutation.mutate({
          recovery_token: tk,
        });
      }
    }, 100)

    return () => clearTimeout(timeout);
  }, [searchParams]);

  const handleResetPassword = e => {
    e.preventDefault();
    setError('');

    if (password !== confirmPass) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    const tk = searchParams.get('tk');

    resetMutation.mutate({
      recovery_token: tk,
      new_password: password,
    });
  };

  return (
    <FullPageLayout image={BackgroundImage}>
      <Container alignItems='center' width={552} gap={16}>
        {verificationMutation.isLoading && (
          <>
            <Container width='100%' alignItems='center'>
              <Loader/>
            </Container>
            <Typography
              color='neutral'
              fontSize={16}
              lineHeight={24}
              textAlign='center'
            >
              Espere un momento por favor.
            </Typography>
          </>
        )}
        {verificationMutation.isSuccess && (
          <>
            <Container width='100%' alignItems='center'>
              <img
                src={resetMutation.isSuccess ? SuccessImage : ForgotImage}
                alt='Reiniciar contraseña'
                style={{
                  width: 127,
                  height: 174,
                }}
              />
            </Container>
            <Typography
              as='h2'
              color='primary'
              fontSize={32}
              lineHeight={40}
              fontWeight={700}
              textAlign='center'
              mb={16}
            >
              {resetMutation.isSuccess
                ? 'Contraseña actualizada'
                : 'Ingresa tu nueva contraseña'
              }
            </Typography>

            {resetMutation.isSuccess ? (
              <>
                <Typography
                  color='neutral'
                  variant='dark'
                  fontSize={14}
                  lineHeight={24}
                  textAlign='center'
                  mb={16}
                >
                  Se actualizo tu contraseña, ya puedes iniciar sesión con tu nueva contraseña.
                </Typography>

                <Button
                  fluid
                  onClick={() => navigate('/login')}
                  content='Ir al login'
                />
              </>
            ) : (
              <Form onSubmit={handleResetPassword}>
                <Form.Field
                  password
                  label='Contraseña'
                  placeholder='Ingresa tu nueva contraseña'
                  value={password}
                  onChange={value => setPassword(value)}
                  errorMessage={error}
                />

                <Form.Field
                  password
                  label='Confirma tu contraseña'
                  placeholder='Confirma tu nueva contraseña'
                  value={confirmPass}
                  onChange={value => setConfirmPass(value)}
                  errorMessage={error}
                />

                <Form.Button
                  fluid
                  loading={resetMutation.isLoading}
                  onClick={handleResetPassword}
                  content='Confirmar'
                />
              </Form>
            )}
          </>
        )}
        {verificationMutation.isError && (
          <>
            <Typography
              as='h2'
              color='primary'
              fontSize={32}
              lineHeight={40}
              fontWeight={700}
              textAlign='center'
            >
              Ocurrio un error
            </Typography>
            <Typography
              color='neutral'
              fontSize={16}
              lineHeight={24}
              textAlign='center'
            >
              Hubo un error, el link ingresado es incorrecto o ha caducado.
            </Typography>
          </>
        )}
      </Container>
    </FullPageLayout>
  );
};

export default VerifyAccount;