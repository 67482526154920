import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container } from 'components/atoms';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { colorValues } from '../../../util/theme';

const DropdownContainer = styled.div`
  position: relative;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  padding: 8px 0;
  border-radius: 16px;
  box-shadow: 0 6px 12px 0 rgba(12, 39, 31, 0.20);
  background-color: ${props => props.theme.colors.grayscale.lightest};
  z-index: 3;
`;

const Option = styled.div`
  display: flex;
  padding: 0 8px;
  gap: 8px;
  cursor: pointer;
  color: ${props => props.color ? props.theme.colors[props.color].dark : props.theme.colors.textBlack};
  
  svg {
    color: ${props => props.color ? props.theme.colors[props.color].dark : props.theme.colors.textBlack};
  }
`;

const Dropdown = ({
  icon,
  content,
  options,
}) => {
  const ref = useRef();
  const [showOptions, setShowOptions] = useState(false);

  useOutsideClick(ref, () => setShowOptions(false));

  return (
    <DropdownContainer
      ref={ref}
    >
      <Container
        padding={5}
        gap={8}
        onClick={() => setShowOptions(true)}
        style={{
          cursor: 'pointer',
        }}
      >
        {content}
        {icon}
      </Container>
      {showOptions && (
        <OptionsWrapper>
          {options.map(opt => (
            <Option
              key={opt.id}
              onClick={() => {
                setShowOptions(false);
                opt.onClick();
              }}
              color={opt.color}
            >
              {opt.icon}
              {opt.label}
            </Option>
          ))}
        </OptionsWrapper>
      )}
    </DropdownContainer>
  );
};

Dropdown.propTypes = {
  icon: PropTypes.node,
  content: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    icon: PropTypes.node,
    color: PropTypes.oneOf(colorValues),
    onClick: PropTypes.func.isRequired,
  })),
};

Dropdown.defaultProps = {
  icon: undefined,
  content: undefined,
  options: [],
};

export default Dropdown;