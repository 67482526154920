import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Button, Col, Container, Grid, Row, Typography } from 'components/atoms';
import styled from 'styled-components';
import { capitalize } from '../../../util/functions';
import { useNavigate } from 'react-router-dom';

const FieldWrapper = styled.div`
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.primary.dark};
  margin-bottom: 16px;
`;

const Field = ({
  label,
  value,
}) => (
  <FieldWrapper>
    <Typography
      as='span'
      color='primary'
      variant='dark'
      fontSize={12}
      fontWeight={16}
      lineHeight={16}
      mb={8}
    >
      {label}
    </Typography>
    <Typography
      fontSize={16}
      lineHeight={24}
      ml={24}
    >
      {value}
    </Typography>
  </FieldWrapper>
);

const Profile = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.session.user);

  return (
    <Container width='100%'>
      <Typography
        as='h2'
        fontSize={32}
        lineHeight={40}
        fontWeight={600}
      >
        Mi Perfil
      </Typography>

      <Container
        width='100%'
        direction='row'
        alignItems='center'
        justify='flex-end'
        gap={16}
        mt={16}
        mb={32}
      >
        <Button
          gap={8}
          weight={400}
          type='borderless'
          onClick={() => navigate('/mi-perfil/cambiar-carrera')}
          color='highlight'
          content='Cambiar carrera'
          icon={<FontAwesomeIcon icon={faPenToSquare} />}
        />
      </Container>

      <Grid>
        <Row>
          <Col sm={8}>
            <Field
              label='Nombre'
              value={capitalize(`${user.first_name} ${user.last_name}`)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <Field
              label='Universidad'
              value={user.student_data?.institution_career?.institution?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <Field
              label='Carrera a la que postula'
              value={user.student_data?.institution_career?.career?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <Field
              label='Correo electrónico'
              value={user.email}
            />
          </Col>
        </Row>
      </Grid>
    </Container>
  )
};

export default Profile;