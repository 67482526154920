import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
  },
});

instance.interceptors.request.use(
  async config => {
    const authToken = localStorage.getItem(process.env.NODE_ENV !== 'production' ? 'AUTH_SKILLUP_DEV' : 'AUTH_SKILLUP');

    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }

    if (authToken && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  err => Promise.reject(err)
);

instance.interceptors.response.use(
  response => response,
  async error => {
    const originalConfig = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;

      try {
        // TODO: refresh token

        // originalConfig.headers.Authorization = `bearer ${access_token}`;

        return instance(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }

    if (!error.response) {
      console.log('Please check your internet connection.');
    }

    return Promise.reject(error);
  }
);

export default instance;
