import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Container, Skeleton, Typography } from 'components/atoms';
import { CardSelect, FormField } from 'components/molecules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const Grid = styled.div`
  flex: 1;
  width: 100%;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  overflow-y: scroll;
`;

const CardSkeleton = () => (
  <Container width='100%' alignItems='center'>
    <Skeleton width={100} height={100} variant='circular' mb={16} />
    <Skeleton width={120} />
  </Container>
);

const StepOne = ({
  list,
  loading,
  onNext,
  onSelect,
  selectedInstitution,
}) => {
  const [search, setSearch] = useState('');
  const [type, setType] = useState('uni');
  const [showList, setShowList] = useState([]);

  useEffect(() => {
    if (list) {
      setShowList(list);
    }
  }, [list]);

  useEffect(() => {
    if (search.length > 2) {
      const newList = list.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
      setShowList(newList);
    } else if (list) {
      setShowList(list);
    }
  }, [search]);

  return (
    <Container flex={1} width='100%' alignItems='center'>
      <Container
        width='100%'
        maxWidth={544}
        mb={32}
        gap={16}
      >
        <FormField
          value={search}
          placeholder='Buscar universidad o instituto'
          onChange={value => setSearch(value)}
          icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
        />

        <Container
          width='100%'
          direction='row'
          borderColor='info'
          borderVariant='darkest'
          borderRadius={16}
          style={{
            overflow: 'hidden',
          }}
        >
          <Container
            // width='50%'
            width='100%'
            alignItems='center'
            padding={8}
            bgColor={type === 'uni' ? 'info' : undefined}
            bgVariant={type === 'uni' ? 'darkest' : undefined}
            onClick={() => setType('uni')}
            style={{
              cursor: 'pointer',
            }}
          >
            <Typography
              pointer
              as='span'
              color={type === 'uni' ? 'grayscale' : 'neutral'}
              variant={type === 'uni' ? 'lightest' : 'light'}
              fontSize={16}
              lineHeight={24}
            >
              Universidad
            </Typography>
          </Container>
          {/*<Container*/}
          {/*  width='50%'*/}
          {/*  alignItems='center'*/}
          {/*  padding={8}*/}
          {/*  bgColor={type === 'inst' ? 'info' : undefined}*/}
          {/*  bgVariant={type === 'inst' ? 'darkest' : undefined}*/}
          {/*  onClick={() => setType('inst')}*/}
          {/*  style={{*/}
          {/*    cursor: 'pointer',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Typography*/}
          {/*    pointer*/}
          {/*    as='span'*/}
          {/*    color={type === 'inst' ? 'grayscale' : 'neutral'}*/}
          {/*    variant={type === 'inst' ? 'lightest' : 'light'}*/}
          {/*    fontSize={16}*/}
          {/*    lineHeight={24}*/}
          {/*  >*/}
          {/*    Institutos*/}
          {/*  </Typography>*/}
          {/*</Container>*/}
        </Container>
      </Container>

      <Container flex={1} width='100%' maxHeight='calc(90vh - 418px)'>
        <Grid>
          {loading ? (
            [...Array(6)].map((e, i) => (
              <CardSkeleton key={i} />
            ))
          ) : (
            showList.map(item => (
              <CardSelect
                key={item.uuid}
                image={item.img}
                selected={selectedInstitution === item.uuid}
                name={item.name}
                onClick={() => onSelect(item.uuid)}
              />
            ))
          )}
        </Grid>
      </Container>

      <Container width='100%' mt={32}>
        <Button
          fluid
          disabled={!selectedInstitution}
          onClick={() => onNext()}
          content='Continuar'
          icon={<FontAwesomeIcon icon={faArrowRightLong} />}
          iconPosition='right'
        />
      </Container>

    </Container>
  );
};

export default StepOne;