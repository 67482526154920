import React from 'react';
import PropTypes from 'prop-types';
import { Container, Skeleton } from '../../atoms';

const SkeletonTable = () => (
  <Container width='100%' gap={8}>
    {[...Array(6)].map((e, i) => (
      <Skeleton key={i} width='100%' />
    ))}
  </Container>
)

const Skeletons = ({
  type,
}) => {
  const renderSkeleton = () => {
    switch (type) {
      case 'table':
        return <SkeletonTable />
    }
  };

  return renderSkeleton();
};

Skeletons.propTypes = {
  type: PropTypes.oneOf(['table']).isRequired,
};

export default Skeletons;