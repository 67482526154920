import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Skeleton = styled.div`
  width: ${props => typeof props.width === 'string' ? props.width : `${props.width}px`};
  height: ${props => `${props.height}px`};
  border-radius: ${props => props.variant === 'circular' ? '2000px': '8px'};
  background-color: ${props => props.theme.colors.grayscale.medium};
  overflow: hidden;
  position: relative;
  margin: ${props => `${props.margin}px`};
  ${props => props.mt && `margin-top: ${props.mt}px;`}
  ${props => props.mb && `margin-bottom: ${props.mb}px;`}
  ${props => props.mr && `margin-right: ${props.mr}px;`}
  ${props => props.ml && `margin-left: ${props.ml}px;`}
  &:after {
    content: '';
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.08), transparent);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateX(-100%);
    animation: waveAnimation 1.6s linear 0.5s infinite;
  }

  @keyframes waveAnimation {
    0% {
      transform: translateX(-100%);
    }
    50% {
      /* +0.5s of delay between each loop */
      transform: translateX(100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

Skeleton.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.number,
  variant: PropTypes.oneOf(['circular', 'square']),
  margin: PropTypes.number,
  mt: PropTypes.number,
  mb: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
};

Skeleton.defaultProps = {
  height: 24,
  variant: 'square',
  margin: 0,
  mt: undefined,
  mb: undefined,
  mr: undefined,
  ml: undefined,
};

export default Skeleton;