import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Alternative, Container, Typography } from 'components/atoms';
import { sendAnswer, storageAnswer } from 'store/examProcess';

const Paragraph = styled.div`
  margin: 8px 0 16px;
  
  p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.colors.neutral.dark};
  }
`;

const Question = ({ onlyRead, data, subject }) => {
  const dispatch = useDispatch();
  const answers = useSelector(state => state.examProcess.answers);
  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    if (!onlyRead) {
      const answerObj = answers.find(item => item.answer === data.answers[0].uuid);

      if (answerObj) {
        setAnswer(answerObj.alternative);
      }
    } else {
      setAnswer(data.answers[0].alternative);
    }
  }, [answers]);

  const handleAlternative = id => {
    if (onlyRead) return;

    dispatch(storageAnswer({
      answer: data.answers[0].uuid,
      alternative: id === answer ? null : id,
      alreadyAnswered: !!answer,
      subject,
    }));
    dispatch(sendAnswer({
      answer: data.answers[0].uuid,
      alternative: id === answer ? null : id,
    }));
  };

  return (
    <Container mt={8}>
      <Typography
        color='primary'
        variant='dark'
        fontSize={16}
        lineHeight={24}
        fontWeight={600}
      >
        Pregunta {data.order}
      </Typography>

      <Paragraph dangerouslySetInnerHTML={{ __html: data.question }} />

      {data.alternatives.map(alternative => (
        <Alternative
          key={alternative.uuid}
          onlyRead={onlyRead}
          check={answer === alternative.uuid}
          onCheck={() => handleAlternative(alternative.uuid)}
          data={alternative}
        />
      ))}
    </Container>
  );
};

export default Question;