import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button, Container, Skeleton, Typography } from 'components/atoms';
import { Skeletons } from 'components/molecules';
import { DataTable } from 'components/organisms';
import { getProcesses } from 'util/request';
import { formatDate } from 'util/functions';
import { phaseDisplay, STATUS_PROCESSED } from 'util/constants';
import Document from 'assets/images/svgs/document-normal.svg';

const MyExams = () => {
  const navigate = useNavigate();
  const processQuery = useQuery(
    ['myExams'],
    () => getProcesses({
      status_in: STATUS_PROCESSED,
    }).then(({ data }) => data),
  );

  const headers = useMemo(() => ([
    {
      id: 'exam_date',
      label: 'Examen',
      cell: item => <>{item.institution_career.institution.abbreviation} {item.exam_process.year} - {item.exam_process.phase}<br />{item.institution_career.career.name}</>,
    },
    {
      id: 'date',
      label: 'Fecha de evaluación',
      cell: item => formatDate(item.created_at),
    },
    {
      id: 'grade',
      label: 'Puntaje obtenido',
      cell: item => item.grade,
    },
    {
      id: 'intents',
      label: 'Intentos',
      cell: item => item.times,
    },
    {
      id: 'good',
      label: '% de Acierto',
      cell: item => {
        const total = item.general_statistics.good + item.general_statistics.bad + item.general_statistics.not_answered;
        return `${Math.floor((item.general_statistics.good*100)/total)} %`;
      }
    },
    {
      id: 'detail',
      label: '',
      cell: item => (
        <Button
          type='border'
          size='small'
          weight={400}
          onClick={() => navigate(`/mis-examenes/${item.uuid}`)}
          content='Ver resultados'
        />
      )
    }
  ]), []);

  return (
    <Container width='100%'>
      {processQuery.isFetching ? (
        <Container width='100%' gap={32}>
          <Skeleton width={300} />
          <Skeletons type='table' />
        </Container>
      ) : (
        <>
          {processQuery.data?.count > 0 ? (
            <>
              <Typography
                as='h2'
                fontFamily='Sans'
                color='primary'
                variant='darkest'
                fontSize={24}
                lineHeight={40}
                fontWeight={600}
                mb={32}
              >
                Historial de exámenes
              </Typography>

              <DataTable
                headers={headers}
                data={processQuery.data?.results}
              />
            </>
          ) : (
            <Container
              width='100%'
              height={400}
              justify='center'
              alignItems='center'
            >
              <Container
                width={400}
                alignItems='center'
                gap={16}
              >
                <img src={Document} alt='Exámenes' />
                <Typography
                  color='neutral'
                  variant='light'
                  fontSize={16}
                  lineHeight={24}
                  textAlign='center'
                  mb={16}
                >
                  Aún no cuentas con datos de tus evaluaciones. Una vez que completes uno de los exámenes este aparecerá aquí.
                </Typography>
                <Button
                  onClick={() => navigate('/examenes/lista')}
                  content='Crear examen'
                />
              </Container>
            </Container>
          )}

        </>
      )}
    </Container>
  );
};

export default MyExams;