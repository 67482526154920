import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import styled from 'styled-components';
import { Button, Message, Modal, Typography } from 'components/atoms';
import { Form } from 'components/molecules';
import { userRequestResetPassword } from 'util/request';
import { mapErrors } from '../../../util/functions';
import { setStudentData } from '../../../store/session';

const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ModalResetPassword = ({
  show,
  onClose,
}) => {
  const resetPasswordMutation = useMutation(userRequestResetPassword);
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({
    email: '',
  });

  useEffect(() => {
    setEmail('');
  }, [show]);

  const handleResetPassword = (e) => {
    e.preventDefault();
    resetPasswordMutation.mutate({ email }, {
      onError: ({ response }) => {
        const { data } = response;
        const err = mapErrors(data.errors);
        setErrors(err);
      }
    });
  };

  const handleOnClose = () => {
    onClose();
    setTimeout(() => {
      resetPasswordMutation.reset();
    }, 500);
  };

  return (
    <Modal
      overlayClose={!resetPasswordMutation.isLoading}
      show={show}
      maxWidth={568}
      onClose={onClose}
    >
      <ModalContent>
        <Typography
          color='primary'
          variant='dark'
          fontSize={32}
          lineHeight={40}
          fontWeight={700}
          textAlign='center'
          mb={16}
        >
          ¿Olvidaste tu contraseña?
        </Typography>

        <Typography
          color='neutral'
          variant='dark'
          fontSize={14}
          lineHeight={24}
          textAlign='center'
          mb={16}
        >
          {resetPasswordMutation.isSuccess
            ? 'Te enviamos un correo con los pasos que debes seguir para recuperar tu cuenta.'
            : 'Por favor ingresa tu dirección de correo electrónico, te enviaremos un mensaje para que puedas reestablecer tu contraseña.'
          }
        </Typography>

        {resetPasswordMutation.isSuccess ? (
          <Button
            fluid
            content='Listo'
            onClick={handleOnClose}
          />
        ) : (
          <Form onSubmit={handleResetPassword}>
            <Form.Field
              label='Correo electrónico'
              name='email'
              placeholder='Tu correo electrónico'
              value={email}
              onChange={value => setEmail(value)}
              errorMessage={errors.email}
            />

            {errors.non_field_errors && (
              <Message type='warning' mt={16} textAlign='center'>
                {errors.non_field_errors}
              </Message>
            )}

            <Form.Button
              fluid
              loading={resetPasswordMutation.isLoading}
              content='Enviar correo'
              onClick={handleResetPassword}
            />
          </Form>
        )}
      </ModalContent>
    </Modal>
  );
}

ModalResetPassword.propTypes = {

};

ModalResetPassword.defaultProps = {

};

export default ModalResetPassword;