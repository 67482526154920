import { css } from 'styled-components';

const breakpoints = {
  xs: 0,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1600,
};

const containerWidth = {
  xs: '100%',
  sm: 723,
  md: 933,
  lg: 1127,
  xl: 1300,
};

const media = {
  xs: styles => css`${styles}`,
  sm: (styles) => css`
    @media only screen and (min-width: ${breakpoints.sm}px) {
      ${css`${styles}`}
    }
  `,
  md: (styles) => css`
    @media only screen and (min-width: ${breakpoints.md}px) {
      ${css`${styles}`}
    }
  `,
  lg: (styles) => css`
    @media only screen and (min-width: ${breakpoints.lg}px) {
      ${css`${styles}`}
    }
  `,
};


export { breakpoints, containerWidth, media };