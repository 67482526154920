import Login from 'pages/public/login';
import Register from 'pages/public/register';
import VerifyAccount from 'pages/public/verify-account';
import RecoveryAccount from 'pages/public/recovery-account';
import Dashboard from 'pages/app/dashboard';
import Settings from 'pages/app/settings';
import PickExam from 'pages/app/exams/pick-exam';
import ExamProcess from 'pages/app/exam-process';
import MyExams from 'pages/app/my-exams';
import ProcessDetail from 'pages/app/my-exams/process-detail';
import ExamDetail from 'pages/app/my-exams/process-detail/exam-detail';
import Ranking from 'pages/app/ranking';
import Profile from 'pages/app/profile';
import ChangeCareer from 'pages/app/profile/change-career';

const routes = [
  {
    index: true,
    path: '/login',
    element: <Login />,
  },
  {
    path: '/registro',
    element: <Register />
  },
  {
    path: '/verificar-cuenta',
    element: <VerifyAccount />,
  },
  {
    path: '/recuperar-cuenta',
    element: <RecoveryAccount />,
  },
];

const protectedRoutes = [
  {
    index: true,
    path: '/',
    element: <Dashboard />,
  },
  {
    path: '/mi-perfil',
    element: <Profile />,
  },
  {
    path: '/mi-perfil/cambiar-carrera',
    element: <ChangeCareer />,
  },
  {
    path: '/opciones',
    element: <Settings />
  },
  {
    path: '/examenes/lista',
    element: <PickExam />
  },
  {
    path: '/mis-examenes',
    element: <MyExams />,
  },
  {
    path: '/mis-examenes/:processId',
    element: <ProcessDetail />,
  },
  {
    path: '/mis-examenes/:processId/:examId',
    element: <ExamDetail />,
  },
  {
    path: '/ranking',
    element: <Ranking />,
  },
];

const protectedRoutesNoLayout = [
  {
    path: '/examen/:processId/:examId',
    element: <ExamProcess />
  },
  {
    path: '/mis-examenes/:processId/:examId/examen',
    element: <ExamProcess review />
  }
];

export { protectedRoutes, protectedRoutesNoLayout, routes };