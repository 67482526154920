import React from 'react';
import styled from 'styled-components';
import { Container, Typography } from '../../atoms';
import PropTypes from 'prop-types';

const Card = styled.div`
  display: flex;
  align-items: center;
  gap: 16px 24px;
  ${props => props.justify && 'justify-content: center;'}
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;
  background: ${props => props.theme.colors.primary[props.selected ? 'light' : 'lightest']};
  
  img {
    width: 100px;
    height: 150px;
    object-fit: contain;
  }
`;

const CardSelect = ({ image, name, selected, onClick, justify }) => (
  <Card
    selected={selected}
    onClick={onClick}
    justify={justify}
  >
    {image && (
      <img
        src={image}
        alt={name}
      />
    )}

    <Typography
      pointer
      as='h3'
      color='primary'
      variant='darkest'
      fontSize={12}
      lineHeight={16}
      fontWeight={600}
      textAlign='center'
    >
      {name}
    </Typography>
  </Card>
);

CardSelect.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.node,
  justify: PropTypes.bool,
};

CardSelect.defaultProps = {
  image: undefined,
  justify: false,
}

export default CardSelect;