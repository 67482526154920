import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Container, Skeleton, Typography } from 'components/atoms';
import { CardSelect, FormField } from 'components/molecules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const Grid = styled.div`
  flex: 1;
  width: 100%;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  overflow-y: scroll;
`;

const LineSkeleton = () => (
  <Container alignItems='center'>
    <Skeleton width={200} />
  </Container>
);

const StepTwo = ({
  list,
  loading,
  isCreating,
  onNext,
  onSelect,
  selectedCareer,
}) => {
  const [search, setSearch] = useState('');
  const [showList, setShowList] = useState([]);

  useEffect(() => {
    if (list) {
      setShowList(list);
    }
  }, [list]);

  useEffect(() => {
    if (search.length > 2) {
      const newList = list.filter(item => item.career.name.toLowerCase().includes(search.toLowerCase()));
      setShowList(newList);
    } else if (list) {
      setShowList(list);
    }
  }, [search]);

  return (
    <Container flex={1} width='100%' alignItems='center'>
      <Container
        width='100%'
        direction='row'
        alignItems='center'
        maxWidth={544}
        mb={32}
        gap={16}
      >
        <FormField
          value={search}
          placeholder='Buscar carrera'
          onChange={value => setSearch(value)}
          icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
        />
      </Container>

      <Container flex={1} width='100%' maxHeight='calc(90vh - 360px)'>
        <Grid>
          {loading ? (
            [...Array(6)].map((e, i) => (
              <LineSkeleton key={i} />
            ))
          ) : (
            showList.map(item => (
              <CardSelect
                key={item.uuid}
                justify
                selected={selectedCareer === item.uuid}
                name={item.career.name}
                onClick={() => onSelect(item.uuid)}
              />
            ))
          )}
        </Grid>
      </Container>

      <Container width='100%' mt={32}>
        <Button
          fluid
          disabled={!selectedCareer}
          loading={isCreating}
          onClick={() => onNext()}
          content='Finalizar'
        />
      </Container>
    </Container>
  );
};

export default StepTwo;