import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Container, Typography } from '../../atoms';
import { useSelector } from 'react-redux';

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 16px 24px;
  background-color: ${props => props.focus ? props.theme.colors.primary.medium : props.theme.colors.grayscale.lightest};
  cursor: pointer;
`;

const Answered = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.highlight[props.focus ? 'medium' : 'light']};
  color: ${props => props.theme.colors.neutral.lightest};
  font-size: 10px;
  line-height: 16px;
`;

const ProgressWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 16px;
  height: 4px;
  background-color: ${props => props.theme.colors.neutral.lightest};
`;

const ProgressBar = styled.div`
  position: absolute;
  width: ${props => props.percent}%;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 16px;
  background-color: ${props => props.theme.colors.info.darkest};
`;

const Progress = ({ percent }) => (
  <ProgressWrapper >
    <ProgressBar percent={percent} />
  </ProgressWrapper>
)

const CourseOption = ({
  name,
  focus,
}) => {
  const options = useSelector(state => state.examProcess.sideOptions[name]);
  const percent = useMemo(() => Math.floor((options.answered * 100)/options.total), [options])

  const handleSubjectScroll = () => {
    const section = window.document.getElementById(name);

    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <OptionWrapper
      focus={focus}
      onClick={handleSubjectScroll}
    >
      <Container
        direction='row'
        alignItems='center'
        gap={8}
      >
        <Answered focus={focus}>
          {options.answered}
        </Answered>
        <Typography
          color='neutral'
          variant={focus ? 'lightest' : 'light'}
          fontSize={10}
          lineHeight={16}
        >
          Respondidas
        </Typography>
      </Container>

      <Container width='100%'>
        <Container
          width='100%'
          direction='row'
          alignItems='center'
          justify='space-between'
        >
          <Typography
            color={focus ? 'neutral' : 'primary'}
            variant={focus ? 'lightest' : 'darkest'}
            fontSize={16}
            lineHeight={24}
            fontWeight={600}
          >
            {name}
          </Typography>

          <Typography
            color='info'
            variant='darkest'
            fontSize={16}
            lineHeight={24}
            fontWeight={600}
          >
            {percent}%
          </Typography>
        </Container>
        <Typography
          color='neutral'
          variant={focus ? 'lightest' : 'medium'}
          fontSize={10}
          lineHeight={16}
        >
          {options.total} Preguntas en total
        </Typography>
      </Container>

      <Progress percent={percent} />
    </OptionWrapper>
  );
};

export default CourseOption;