import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Container, Modal, Typography } from 'components/atoms';

const ModalContent = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth} 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  
  img {
    max-width: 220px;
  }
`;

const ModalDisclaim = ({
  maxWidth,
  show,
  onClose,
  showCloseIcon,
  overlayClose,
  imageSrc,
  image,
  title,
  description,
  btnConfirmText,
  onConfirm,
  btnCancelText,
  onCancel,
  loading,
}) => (
  <Modal
    show={show}
    onClose={onClose}
    showCloseIcon={showCloseIcon}
    overlayClose={overlayClose}
  >
    <ModalContent maxWidth={maxWidth}>
      {title && (
        <Typography
          color='primary'
          variant='dark'
          fontSize={24}
          fontWeight={700}
          lineHeight={32}
          textAlign='center'
        >
          {title}
        </Typography>
      )}
      {imageSrc && (
        <img src={imageSrc} alt={title} />
      )}
      {image}
      {description && (
        <Typography
          color='neutral'
          variant='dark'
          fontSize={14}
          lineHeight={24}
          textAlign='center'
        >
          {description}
        </Typography>
      )}
      <Container
        width='100%'
        gap={16}
        direction='row'
        alignItems='center'
        justify='center'
      >
        <Container>
          <Button
            loading={loading}
            onClick={onConfirm}
            content={btnConfirmText}
          />
        </Container>

        {(onCancel && btnCancelText) && (
          <Container>
            <Button
              type='border'
              disabled={loading}
              onClick={onCancel}
              content={btnCancelText}
            />
          </Container>
        )}
      </Container>
    </ModalContent>
  </Modal>
);

ModalDisclaim.propTypes = {
  maxWidth: PropTypes.number,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showCloseIcon: PropTypes.bool,
  overlayClose: PropTypes.bool,
  imageSrc: PropTypes.string,
  image: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  btnConfirmText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  btnCancelText: PropTypes.string,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
};

ModalDisclaim.defaultProps = {
  maxWidth: 368,
  showCloseIcon: false,
  overlayClose: false,
  imageSrc: '',
  image: undefined,
  title: '',
  description: '',
  btnConfirmText: 'Confirmar',
  btnCancelText: 'Cancelar',
  onCancel: undefined,
  loading: false,
};

export default ModalDisclaim;