import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Container } from 'components/atoms';
import { CourseOption, ModalDisclaim } from 'components/molecules';
import { HEADER_HEIGHT, processStatus, SIDE_MENU_WIDTH } from 'util/constants';
import { useDispatch, useSelector } from 'react-redux';
import { finishProcess } from 'store/examProcess';
import Logo from 'assets/images/logo_white.svg';
import ModalImage from 'assets/images/ilustrations/finish_exam.svg';
import { Link, useNavigate } from 'react-router-dom';

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  width: ${SIDE_MENU_WIDTH}px;
  height: 100vh;
  position: fixed;
  z-index: 100;
  box-shadow: 4px 0 16px 0 rgba(12, 39, 31, 0.15);
`;

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.primary.medium};
  padding: 12px 0;
  
  //a {
  //  display: flex;
  //  width: 100%;
  //}
  
  img {
    height: 60px;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  
  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }

  .container::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.neutral.lightest};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.info.medium};
    border-radius: 4px;
  }
`;

const ASide = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const examStatus = useSelector(state => state.examProcess.status);
  const statements = useSelector(state => state.examProcess.statement);
  const focus = useSelector(state => state.examProcess.focus);
  const loading = useSelector(state => state.examProcess.loadingFinish);
  const [options, setOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (statements.length > 0) {
      const options = [...new Set(statements.map(item => item.course.name))];
      setOptions(options);
    }
  }, [statements]);

  const handleOnFinish = () => dispatch(finishProcess());

  const handleOnButton = () => {
    if (processStatus.PROCESSED === examStatus) {
      navigate(-1);
    } else {
      setShowModal(true);
    }
  }

  return (
    <Wrapper>
      <LogoContainer>
        <Link to='/'>
          <img src={Logo} alt='Skillup logo' />
        </Link>
      </LogoContainer>

      <OptionsContainer>
        {options.map(option => (
          <CourseOption
            key={option}
            name={option}
            focus={focus === option}
          />
        ))}
      </OptionsContainer>

      <Container
        width='100%'
        style={{
          padding: '16px 24px',
        }}
      >
        <Button
          width='100%'
          onClick={handleOnButton}
          content={processStatus.PROCESSED === examStatus ? 'Salir del examen' : 'Finalizar examen'}
        />
      </Container>

      <ModalDisclaim
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleOnFinish}
        title='¿Estás seguro de finalizar el examen?'
        description='Pueda que necesites corregir alguna respuesta o se te haya olvidado alguna pregunta. Si no es el caso esperamos que consigas una nota grandiosa en esta evaluación.'
        btnConfirmText='Finalizar examen'
        btnCancelText='Continuar con mi examen'
        onCancel={() => setShowModal(false)}
        imageSrc={ModalImage}
        loading={loading}
      />
    </Wrapper>
  );
};

export default ASide;