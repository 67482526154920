import React from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT, phaseDisplay, processStatus, SIDE_MENU_WIDTH } from 'util/constants';
import { Container, Typography } from '../../../components/atoms';
import { Chronometer } from '../../../components/molecules';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  padding: 0 64px 0 ${SIDE_MENU_WIDTH + 64}px;
  box-shadow: 0 6px 12px 0 rgba(12, 39, 31, 0.20);
  background-color: ${props => props.theme.colors.neutral.lightest};
  z-index: 99;
`;

const Header = () => {
  const { examId } = useParams();
  const examStatus = useSelector(state => state.examProcess.status);
  const data = useSelector(state => state.examProcess.examData);
  const grade = useSelector(state => state.examProcess.grade);
  console.log(examStatus);
  return (
    <HeaderWrapper>
      <Container
        direction='row'
        alignItems='center'
        gap={8}
      >
        <Typography
          as='h3'
          fontFamily='Sans'
          color='primary'
          fontSize={40}
          lineHeight={48}
          fontWeight={800}
        >
          {data.institutionData?.institution?.abbreviation}
        </Typography>

        <Typography
          color='highlight'
          variant='light'
          fontSize={12}
          lineHeight={16}
          fontWeight={600}
        >
          {data.examProcess?.year} - {data.examProcess?.phase}
        </Typography>
      </Container>
      {processStatus.STARTED !== examStatus ? (
        <>
          {grade && (
            <Typography
              as='span'
              color='positive'
              fontFamily='Sans'
              fontSize={32}
              fontWeight={700}
              lineHeight={40}
            >
              {grade} ptos.
            </Typography>
          )}
        </>
      ) : (
        <Chronometer />
      )}
    </HeaderWrapper>
  );
};

export default Header;