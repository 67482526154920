import { createGlobalStyle } from 'styled-components';

const THEME = {
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    textLight: '#F9FAFC',
    textBlack: '#0F0C0C',
    grayscale: {
      darkest: '#000000',
      medium: '#DBDBDB',
      light: '#F9F9F9',
      lightest: '#FFFFFF',
    },
    primary: {
      darkest: '#0C271F',
      dark: '#24755D',
      medium: '#3ABC96',
      light: '#B1E7D7',
      lightest: '#EBF9F5',
    },
    highlight: {
      darkest: '#0B0033',
      dark: '#210099',
      medium: '#3700FF',
      light: '#AF99FF',
      lightest: '#EBE5FF',
    },
    neutral: {
      darkest: '#0F0C0C',
      dark: '#392D2D',
      medium: '#715B5B',
      light: '#BBAAAA',
      lightest: '#F4F1F1',
    },
    positive: {
      darkest: '#164920',
      dark: '#309C46',
      medium: '#5EC873',
      light: '#C3EBCB',
      lightest: '#F4FBF5',
    },
    warning: {
      darkest: '#682E20',
      dark: '#B04F36',
      medium: '#DCA699',
      light: '#EBCCC5',
      lightest: '#FCF9F8',
    },
    info: {
      darkest: '#412D91',
      dark: '#8E7DD3',
      medium: '#B7ACE3',
      light: '#E2DEF4',
      lightest: '#FAF9FD',
    },
  },
};

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Sora', 'sans-serif';
    
    button {
      font-family: 'Sora', 'sans-serif';
    }
    
    a {
      text-decoration: none;
    }
  }
  * {
    box-sizing: border-box;
  }
`;

export const colorValues = Object.keys(THEME.colors);
export const variantValues = ['darkest', 'dark', 'medium', 'light', 'lightest'];

export default THEME;